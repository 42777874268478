import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IApiKeyWithoutKeyValue } from "../../interfaces/IApiKeyWithoutKeyValue";
import useDeleteApiKeyQuery from "../../queries/useDeleteApiKeyQuery";

interface IProps {
  handleCloseDialog: () => void;
  dataItem: IApiKeyWithoutKeyValue;
}
const DeleteApiKeyDialog = (props: IProps) => {
  const deleteApiKeyQuery = useDeleteApiKeyQuery();

  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  const hanldeDeleteApiKey = () => {
    deleteApiKeyQuery.mutate(props.dataItem.id, {
      onSuccess: () => {
        handleCloseDialog();
      },
    });
  };

  return (
    <>
      <Dialog title={"Api Key Löschen"} onClose={handleCloseDialog} width={400}>
        <p>Wollen Sie diesen Api Key wirklich löschen?</p>
        <DialogActionsBar>
          <Button onClick={handleCloseDialog}>Nein</Button>
          <Button onClick={hanldeDeleteApiKey}>Ja</Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DeleteApiKeyDialog;
