import React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";

interface IPropsAlwaysRedirectCheckbox {
  isChecked: boolean;
  onChange: (event: any) => void;
}

const AlwaysRedirectCheckbox  = ({ isChecked, onChange }: IPropsAlwaysRedirectCheckbox) => {
  return (
    <div className="checkbox-grey">
            <Checkbox
              checked={isChecked}
              onChange={onChange}
              label={
                "Bei nächster Anmeldung automatisch zu gewähltem Unternehmen weiterleiten."
              }
            ></Checkbox>
          </div>
  );
};

export default AlwaysRedirectCheckbox ;
