import {
  GridColumn as Column,
  Grid,
  GridToolbar,
  GridSortChangeEvent
} from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import DateCell from "../../../../global/components/tableCells/DateCell";
import { IAccount } from "../../../../global/interfaces/IAccount";
import useGetAllAccountsQuery from "../../queries/useGetAllAccountsQuery";
import AccountActionsCell from "./AccountActionsCell";
import AddAccount from "./AddAccount";
import AssociatedTenantsCell from "./AssociatedTenantsCell";
import RollenCell from "./RollenCell";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

const DATA_ITEM_KEY: string = "email";
const AccountsTable = () => {
  const [allAccounts, setAllAccounts] = useState<IAccount[]>([]);

  const initialSort: Array<SortDescriptor> = [
    { field: DATA_ITEM_KEY, dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const {
    refetch: refetchAllAccounts,
    data: allFetchedAccounts,
    isFetched: areAllAccountsFetched,
    error: errorGetAllAccounts,
  } = useGetAllAccountsQuery();

  useEffect(() => {
    if (areAllAccountsFetched) {
      if (allFetchedAccounts) {
        setAllAccounts(allFetchedAccounts);
      } else {
        setAllAccounts([]);
      }
    }
  }, [areAllAccountsFetched, allFetchedAccounts]);

  const removeAccountFromAllAccounts = (email: string) => {
    const updatedAccountList = allAccounts.filter(
      (account) => account.email !== email
    );
    setAllAccounts(updatedAccountList);
   refetchAllAccounts();
  };



  return (
    <>
      {allAccounts ? (
        <Grid
          data={orderBy(allAccounts, sort)}
          sortable={true}
          sort={sort}
          style={{
            maxHeight: "400px",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <AddAccount handleAddAccountDone={refetchAllAccounts} />
          </GridToolbar>
          <Column field="email" title="Email" editable={false} />
          <Column
            field="lastLogin"
            title="Zuletzt Angemeldet"
            editable={false}
            cell={DateCell}
          />
          <Column
            field="roles"
            title="Rollen"
            editable={false}
            cell={RollenCell}
          />
          <Column
            field="tenants"
            title="Zugehörige Unternehemen"
            editable={false}
            cell={AssociatedTenantsCell}
          />
          <Column
            field="actions"
            title="Aktionen"
            cell={(props) => (
              <AccountActionsCell
                gridCellProps={props}
                handleDeleteDone={removeAccountFromAllAccounts}
                handleUpdateDone={refetchAllAccounts}
              />
            )}
          />
        </Grid>
      ) : (
        <p>Keine Accounts gefunden.</p>
      )}
    </>
  );
};

export default AccountsTable;
