import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import AddTenantDialog from "./AddTenantDialog";
import SuccessNotificationToast from "../../../../global/components/SuccessNotificationToast";

interface IProps {
  handleAddTenantDone: () => void;
}

const AddTenant = (props: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleCloseDialog = () => {
    props.handleAddTenantDone();
    toggleDialog();
  };

  const hanldeSuccessfulCreation = () =>{
    setShowSuccessToast(true);
    toggleDialog();
    props.handleAddTenantDone();
  }

  return (
    <>
      <Button title="Tenant Erstellen" onClick={toggleDialog}>
      Unternehmen Erstellen
      </Button>
      {showDialog && <AddTenantDialog handleCloseDialog={handleCloseDialog} hanldeSuccessfulCreation={hanldeSuccessfulCreation} />}
      
      <SuccessNotificationToast
        message={"Neues Unternehmen wurde erstellt."}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
      />
    </>
  );
};

export default AddTenant;
