import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ITenant } from "../../../../global/interfaces/ITenant";
import useDeleteTenantQuery from "../../queries/useDeleteTenantQuery";

interface IProps {
  handleDeleteDone: () => void;
  handleCloseDialog: () => void;
  dataItem: ITenant;
}

const DeleteTenantDialog = (props: IProps) => {
  const deleteTenantQuery = useDeleteTenantQuery();

  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  const handleDeleteTenant = () => {
    deleteTenantQuery.mutate(props.dataItem.webserverUrl, {
      onSuccess: () => {
        props.handleDeleteDone();
      },
    });
  };

  return (
    <>
      <Dialog title={"Unternehmen Löschen"} onClose={handleCloseDialog} width={400}>
        <p>Wollen Sie dieses Unternehmen wirklich löschen?</p>
        <DialogActionsBar>
          <Button onClick={props.handleCloseDialog}>Nein</Button>
          <Button onClick={handleDeleteTenant}>Ja</Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DeleteTenantDialog;
