import { Button } from "@progress/kendo-react-buttons";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  FieldWrapper,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Operation, compare } from "fast-json-patch";
import { useEffect, useState } from "react";
import { SubmitTenantData } from "../../../global/types/SubmitTenantData";
import { ITenantBasic } from "../../login/interfaces/ITenantBasic";
import { useLizenznameValidation } from "../hooks/useLizenznameValidation";
import { useWebserverUrlValidation } from "../hooks/useWebserverUrlValidation";

interface IProps {
  handleSubmit: (data: SubmitTenantData) => Promise<void>;
  handleCancel: () => void;
  tenantBasic: ITenantBasic;
  isNewTenant: boolean;
}

const TenantForm = ({
  handleSubmit,
  handleCancel,
  tenantBasic,
  isNewTenant,
}: IProps) => {
  const [formKey, setFormKey] = useState(1);

  // deep copy
  const intitalTenant: ITenantBasic = JSON.parse(JSON.stringify(tenantBasic));

  const [initialValues, setInititalValues] = useState<ITenantBasic>({
    webserverUrl: intitalTenant.webserverUrl ? intitalTenant.webserverUrl : "",
    name: intitalTenant.name ? intitalTenant.name : "",
    description: intitalTenant.description ? intitalTenant.description : "",
    lizenzname: intitalTenant.lizenzname ? intitalTenant.lizenzname : "",
  })

  const {
    webserverUrl,
    handleWebserverUrlChange,
    allowSubmit: allowSubmitWebserverUrl,
    validationError: validationErrorWebserverUrl,
    webserverUrlValidator,
    resetValidator: resetWebserverValidator,
  } = useWebserverUrlValidation("");

  const {
    lizenzname,
    handleLizenznameChange,
    allowSubmit: allowSubmitLizenzname,
    validationError: validationErrorLizenzname,
    lizenznameValidator,
    resetValidator: resetLizenznameValidator,
  } = useLizenznameValidation("");

  const [allowSubmit, setAllowSubmit] = useState(
    allowSubmitWebserverUrl && allowSubmitLizenzname
  );

  useEffect(() => {
        setAllowSubmit(allowSubmitWebserverUrl && allowSubmitLizenzname);
  }, [allowSubmitWebserverUrl, allowSubmitLizenzname]);

  const onSubmit = (formValues: { [name: string]: any }) => {
    if (allowSubmit) {
      const newTenant: ITenantBasic = {
        name: formValues.name,
        description: formValues.description,
        webserverUrl: formValues.webserverUrl,
        lizenzname: formValues.lizenzname,
      };

      if (isNewTenant) {
        handleSubmit(newTenant);
      } else {
        const patch: Operation[] = compare(initialValues, newTenant);
        console.log("patch: ");
        console.log(patch);
        handleSubmit(patch);

        updateInitialValues(newTenant)

      }
    }
  };

  const updateInitialValues = (newTenant: ITenantBasic) =>{
    setInititalValues(newTenant);
  }

  //Reseting the form to the initial values
  const resetForm = () => {
    setFormKey(formKey + 1);
    resetWebserverValidator();
    resetLizenznameValidator();
  };

  const handleCancelForm = () => {
    resetForm();
    handleCancel();
    setAllowSubmit(true);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      key={formKey}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <FieldWrapper>
              <Field
                lang="de"
                name="webserverUrl"
                component={Input}
                labelClassName={"k-form-label"}
                label="Unternehmen webserver Url"
                validator={webserverUrlValidator}
                onChange={handleWebserverUrlChange}
                validationMessage={validationErrorWebserverUrl}
                readOnly={isNewTenant === true ? false : true}
              />
              {validationErrorWebserverUrl && (
                <Error>{validationErrorWebserverUrl}</Error>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <Field
                name="name"
                component={Input}
                labelClassName={"k-form-label"}
                label="Name"
              />
            </FieldWrapper>

            <FieldWrapper>
              <Field
                name="lizenzname"
                component={Input}
                labelClassName={"k-form-label"}
                label="Lizenzname"
                validator={lizenznameValidator}
                onChange={handleLizenznameChange}
                validationMessage={validationErrorLizenzname}
              />
              {validationErrorLizenzname && (
                <Error>{validationErrorLizenzname}</Error>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <Field
                name="description"
                component={Input}
                labelClassName={"k-form-label"}
                label="Beschreibung"
              />
            </FieldWrapper>

            <div>
              <DialogActionsBar>
                <Button type="button" onClick={handleCancelForm}>
                  Abbrechen
                </Button>
                <Button type="submit" disabled={!allowSubmit}>
                  Speichern
                </Button>
              </DialogActionsBar>
            </div>
          </FormElement>
        );
      }}
    />
  );
};

export default TenantForm;
