import ApiKeySicherheitshinweis from "./ApiKeySicherheitshinweis";

const ApiKeyDescription = () => {
  return (
    <>
      <p>
        *txt* Hier können Sie Ihre individuellen API Keys verwalten. API Keys
        ermöglichen eine alternative Authentifizierung um auf unsere Dienste
        zuzugreifen. *txt*
      </p>
      <ApiKeySicherheitshinweis></ApiKeySicherheitshinweis>
    </>
  );
};
export default ApiKeyDescription;
