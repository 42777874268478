import axios, { AxiosError } from "axios";
import { Operation } from "fast-json-patch";
import { useMutation } from "react-query";
import { ITenant } from "../../../global/interfaces/ITenant";
import { PATCH_UPDATE_TENANT } from "../../../global/utils/Constants";
import { IUpdateTenantArguments } from "../interfaces/IUpdateTenantArguments";


const usePatchUpdateTenantQuery = () => {
    return useMutation<ITenant, AxiosError, IUpdateTenantArguments>(
        async ({ webserverUrl, patch }: IUpdateTenantArguments) =>
            await axios.patch(
                PATCH_UPDATE_TENANT + encodeURI(webserverUrl),
                patch,
                { headers: { 'Content-Type': 'application/json-patch+json' } }
            ).then(res => res.data),
        {
        }
    );
}

export default usePatchUpdateTenantQuery;