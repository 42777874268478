import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { IAccount } from "../../../global/interfaces/IAccount";
import { PATCH_UPDATE_ACCOUNT } from "../../../global/utils/Constants";
import { IUpdateAccountArguments } from "../interfaces/IUpdateAccountArguments";


const usePatchUpdateAccountQuery = () => {
    return useMutation<IAccount, AxiosError, IUpdateAccountArguments>(
        async ({ email, patch }: IUpdateAccountArguments) =>
            await axios.patch(
                PATCH_UPDATE_ACCOUNT + encodeURI(email),
                patch,
                { headers: { 'Content-Type': 'application/json-patch+json' } }
            ).then(res => res.data),
        {
        }
    );
}

export default usePatchUpdateAccountQuery;