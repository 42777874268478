import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface AuthState {
    loggedIn: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
    loggedIn: false,
};

const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        },
    },
}
);

export const {setLoggedIn} = authSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectLoggedIn = (state: {auth: AuthState}) => state.auth.loggedIn;
export default authSlice.reducer;