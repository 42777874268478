import axios from "axios";
import { useQuery } from "react-query";
import { IAccount } from "../../../global/interfaces/IAccount";
import { GET_All_ACCOUNTS } from "../../../global/utils/Constants";


const useGetAllAccountsQuery = () => {
    return useQuery({
        queryKey: ["getAllAccounts"],
        queryFn: () => axios.get<IAccount[]>(GET_All_ACCOUNTS)
            .then(res => { 
                return res.data 
            }),
        retry: 1
    })
}

export default useGetAllAccountsQuery;