import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import useGetAllApiKeysQuery from "../../queries/useGetAllApiKeysQuery";
import { useEffect, useState } from "react";
import DeleteApiKeyColumn from "./DeleteApiKeyColumn";
import { IApiKeyWithoutKeyValue } from "../../interfaces/IApiKeyWithoutKeyValue";
import AddApiKey from "./AddApiKey";
import ExpiryDateColumn from "./ExpiryDateColumn";
import axios from "axios";

const ApiKeyTable = () => {
  const [allApiKeys, setAllApiKeys] = useState<IApiKeyWithoutKeyValue[]>([]);
  const {
    refetch: refetchAllApiKeys,
    data: allFetchedApiKeys,
    isFetched,
    isError: isErrorGetAllApiKeys,
    error: errorGetAllApiKeys,
  } = useGetAllApiKeysQuery();

  useEffect(() => {
    if (isFetched) {
      if (allFetchedApiKeys) {
        setAllApiKeys(allFetchedApiKeys);
      } else {
        setAllApiKeys([]);
      }
    }
  }, [isFetched, allFetchedApiKeys]);

  useEffect(() => {
    if (isErrorGetAllApiKeys) {
      if (axios.isAxiosError(errorGetAllApiKeys)) {
        if (errorGetAllApiKeys.status === 401) {
          console.log("detected error 401 from getAllApiKeys!!!!!!!!!!!!!!!!!");
        }
      }
    }
  }, [isErrorGetAllApiKeys, errorGetAllApiKeys]);

  const handleDeleteApiKey = (id: string) => {
    const updatedApiKeys = allApiKeys.filter((apiKey) => apiKey.id !== id);
    setAllApiKeys(updatedApiKeys);
    refetchAllApiKeys();
  };

  return (
    <>
      {allApiKeys ? (
        <Grid
          data={allApiKeys}
          style={{
            maxHeight: "400px",
          }}
        >
          <GridToolbar>
            <AddApiKey handleAddApiKeyDone={refetchAllApiKeys} />
          </GridToolbar>
          <Column field="title" title="Title" editable={false} />
          <Column field="description" title="Beschreibung" editable={false} />
          <Column
            field="expiryDate"
            title="Ablaufdatum"
            cells={{
              data: ExpiryDateColumn,
            }}
            editable={false}
          />
          <Column
            title="Löschen"
            cell={(props) => (
              <DeleteApiKeyColumn
                gridCellProps={props}
                handleDeleteApiKeyDone={handleDeleteApiKey}
              />
            )}
          />
        </Grid>
      ) : (
        <p>keine Daten gefunden. allApiKeys is: {allApiKeys}</p>
      )}
    </>
  );
};

export default ApiKeyTable;
