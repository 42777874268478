import React from "react";
import ABKLogo from "../images/logo_lang.png";

const AbkLogo = () => {
  return (
    <div className="login-logo">
      <img src={ABKLogo} alt="Logo Lang" className="logo-image" />
    </div>
  );
};

export default AbkLogo;
