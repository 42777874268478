import {
  Field,
  FieldWrapper
} from "@progress/kendo-react-form";

import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import MultiSelectFieldInForm from "../../../../../global/components/MultiSelectFieldInForm";
import Roles from "../../../../../global/enums/Roles";
import { ITenant } from "../../../../../global/interfaces/ITenant";
import RolesDropdownMultiselect from "./RolesDropdownMultiselect";

interface EmailFieldProps {
  initialValue?: string;
  onChange: (e: any) => void;
  validator: (value: string) => string | undefined;
  validationError: string | null;
  readOnly: boolean;
}

export const EmailField = ({
  initialValue = "",
  onChange,
  validator,
  validationError,
  readOnly,
}: EmailFieldProps) => (
  <FieldWrapper>
    <Field
      lang="de"
      name="email"
      component={Input}
      labelClassName={"k-form-label"}
      label="Email"
      validator={validator}
      onChange={onChange}
      readOnly={readOnly}
      defaultValue={initialValue}
    />
    {validationError && <Error>{validationError}</Error>}
  </FieldWrapper>
);

interface TenantsFieldProps {
  data: ITenant[];
}

export const TenantsField = ({ data }: TenantsFieldProps) => (
  <FieldWrapper>
    <Field
      name="tenants"
      component={MultiSelectFieldInForm}
      label="Zugehöriges Unternhemen"
      data={data}
      textField="webserverUrl"
      dataItemKey="webserverUrl"
      placeholder="Unternehemen auswählen ..."
    />
  </FieldWrapper>
);

export const RolesDropdownMultiselectField = () => {
  const roleOptions = Object.values(Roles);

  return (
    <FieldWrapper>
      <Field
        id="roles"
        name="roles"
        component={RolesDropdownMultiselect}
        label="Roles"
        data={roleOptions}
        placeholder="Rollen auswählen ..."
      />
    </FieldWrapper>
  );
};
