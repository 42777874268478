import { Button } from "@progress/kendo-react-buttons";
import DeleteIcon from "../../../../global/icons/DeleteIcon";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useState } from "react";
import DeleteTenantDialog from "./DeleteTenantDialog";
import { ITenant } from "../../../../global/interfaces/ITenant";

interface IProps {
  gridCellProps: GridCellProps;
  handleDeleteTenantDone: (webserverUrl: string) => void;
  dataItem: ITenant;
}

const DeleteTenantAction = (props: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dataItem = props.dataItem;

  const toggleDialog = () => {
    setShowDialog((prevShowDialog) => !prevShowDialog);
  };

  const handleCloseDialog = () => {
    toggleDialog();
  };

  const handleDeleteDone = () => {
    props.handleDeleteTenantDone(dataItem.webserverUrl);
    toggleDialog();
  };

  return (
    <>
      <span className="k-command-cell">
        <Button className="no-border" onClick={toggleDialog}>
          <DeleteIcon className={"table-icon"} />
        </Button>
        {showDialog && (
          <DeleteTenantDialog
            dataItem={dataItem}
            handleCloseDialog={handleCloseDialog}
            handleDeleteDone={handleDeleteDone}
          />
        )}
      </span>
    </>
  );
};

export default DeleteTenantAction;
