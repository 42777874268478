import AbkLogo from "../../pages/login/components/AbkLogo";
import LoginHeader from "../../pages/login/components/Header";
import LoginFooter from "../../pages/login/components/LoginFooter";

interface IProps {
  children: React.ReactNode;
  loggedIn: boolean;
  initLoaded?: boolean;
  onLogout?: () => void;
}

const LoginWindowLayout = ({
  children,
  loggedIn,
  initLoaded = true,
  onLogout = () => {},
}: IProps) => {

  return (
    <>
    {initLoaded && <div className="login-background">
        <div className="container">
            {/* {initLoaded &&( */}
                <div className="containerContent">
            <div className="header-container">
              <LoginHeader loggedIn={loggedIn} onLogout={onLogout} />
            </div>
            <div className="main-content-container">
                <AbkLogo/>
                {children}
            </div>
            <div className="footer-container">
              <LoginFooter />
            </div>
          </div>
            {/* )} */}
          
        </div>
      </div>}
      
    </>
  );
};

export default LoginWindowLayout;
