import React from "react";
import TenantSelectionMoreButton from "./TenantSelectionMoreButton";

interface IProps {
  loggedIn: boolean;
  onLogout: () => void;
}

const LoginHeader = ({ loggedIn, onLogout }: IProps) => {
  return (
    /* show more button top right corner above logo to logout or go to the settings page */
    <div className="header-row">
      {loggedIn && <TenantSelectionMoreButton onLogout={onLogout} />}
    </div>
  );
};

export default LoginHeader;
