import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { IAccountUserInfo } from "../../pages/login/interfaces/IAccountUserInfo";
import useCheckSessionQuery from "../../pages/login/queries/useCheckSessionQuery";
import useGetAccountUserInfoQuery from "../../pages/login/queries/useGetAccountUserInfoQuery";
import Roles from "../enums/Roles";
import { selectLoggedIn, setLoggedIn } from "../stateManagement/authSlice";
import {
  selectUser,
  selectUserRoles,
  setUser,
} from "../stateManagement/userSlice";

interface Props {
  redirectPath?: string;
  children?: React.ReactNode;
  requiredRoles?: Roles[];
}

const ProtectedRoute = ({
  redirectPath = "/",
  children,
  requiredRoles,
}: Props) => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const userRoles = useSelector(selectUserRoles);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const {
    data: accountUserInfo,
    isFetched: isFetchedGetAccountUserInfo,
    refetch: fetchUserInfoQuery,
  } = useGetAccountUserInfoQuery();

  const {
    data: loginCheckResult,
    isFetched: isCheckLoginFetched,
    isError: isCheckLoginError,
    error: checkLoginError,
    refetch: fetchLoginCheck,
  } = useCheckSessionQuery();

  const [shouldRedirect, setShouldRedirect] = useState<string | null>(null);

  const [userInfo, setUserInfo] = useState<IAccountUserInfo>();

  useEffect(() => {
    const currentPath = window.location.pathname;
    localStorage.setItem("pathBeforeLogin", currentPath);
  }, [window.location.pathname]);

  // Fetch the session information if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      fetchLoginCheck();
    }
  }, [isLoggedIn, fetchLoginCheck]);

  // Handle the result of the session check; i.e. roles check via loading userAccountInfo
  useEffect(() => {
    if (isCheckLoginFetched) {
      if (isCheckLoginError) {
        if (checkLoginError.response?.status === 401) {
          setShouldRedirect("/login");
        } else {
          console.log(
            "in useEffect of login check -> there is an error other than 401: " +
              checkLoginError.response?.status
          );
        }
      } else {
        if (accountUserInfo === undefined) {
          fetchUserInfoQuery();
        } else if (accountUserInfo) {
          dispatch(setLoggedIn(true));
          dispatch(setUser(accountUserInfo));
        }
      }
    }
  }, [
    loginCheckResult,
    isCheckLoginFetched,
    isCheckLoginError,
    checkLoginError,
    dispatch,
    accountUserInfo,
    fetchUserInfoQuery,
  ]);

  // Handle accountUserInfo
  useEffect(() => {
    if (isFetchedGetAccountUserInfo && accountUserInfo !== undefined) {
      // save it to the state
      setUserInfo(accountUserInfo);
      // save it to the store
      dispatch(setUser(accountUserInfo));
    }
  }, [accountUserInfo, isFetchedGetAccountUserInfo, dispatch]);

  // Handle fetching user info and role validation
  useEffect(() => {
    if (isLoggedIn) {
      if (user && userRoles) {
        // check if user has the correct roles
        if (requiredRoles) {
          const hasRequiredRoles = requiredRoles.every((role: Roles) =>
            userRoles.includes(role)
          );
          if (!hasRequiredRoles) {
            setShouldRedirect("/no-permission");
          }
        }
        // fetch user
      } else if (!user) {
        fetchUserInfoQuery();
      }
    }
  }, [isLoggedIn, requiredRoles, userRoles, user, fetchUserInfoQuery]);

  if (shouldRedirect) {
    return <Navigate to={shouldRedirect} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
