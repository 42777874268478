import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import { selectLoggedIn } from "../../global/stateManagement/authSlice";
import { useSelector } from "../../global/stateManagement/hooks";
import { GET_LOGOUT_URL } from "../../global/utils/Constants";
import ApiKeyContainer from "./components/apiKeys/ApiKeyContainer";
import useUpdateAlwaysRedirectQuery from "./queries/useUpdateAlwaysRedirectQuery";
import BeErrorTest from "./components/apiKeys/BeErrorTest";

const Settings = (props) => {
  const updateAlwaysRedirectQuery = useUpdateAlwaysRedirectQuery();
  const loggedIn = useSelector(selectLoggedIn);

  const handleAlwaysRedirectToggle = (shouldAlwaysRedirect: boolean) => {
    updateAlwaysRedirectQuery.mutate(shouldAlwaysRedirect);
  };


  return (
    <>
      <Button onClick={() => handleAlwaysRedirectToggle(false)}>
        Always redirect OFF
      </Button>
      <Button onClick={() => handleAlwaysRedirectToggle(true)}>
        Always redirect ON
      </Button>
      <BeErrorTest />
      {loggedIn && <a href={GET_LOGOUT_URL}>Local Log out</a>}
      <ApiKeyContainer />
    </>
  );
};

export default Settings;
