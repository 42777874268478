import icon from "./Delete.png";

interface IProps {
  style?: React.CSSProperties;
  className?: string;
}

const DeleteIcon = (props: IProps) => {
  return (
    <img
      src={icon}
      alt="deleteIcon"
      style={props.style}
      className={props.className}
    />
  );
};

export default DeleteIcon;
