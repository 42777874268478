import { useMutation } from "react-query"
import { ICreateApiKey } from "../interfaces/ICreateApiKey"
import axios, { AxiosError } from "axios"
import { POST_CREATE_API_KEY } from "../../../global/utils/Constants"
import { IApiKey } from "../interfaces/IApiKey"

const usePostNewApiKeyQuery = () => {
    return useMutation<IApiKey, AxiosError, ICreateApiKey>(async (newApiKey: ICreateApiKey) =>
        await axios.post(POST_CREATE_API_KEY, 
            newApiKey, 
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.data),
        {
        }
    );

}

export default usePostNewApiKeyQuery


