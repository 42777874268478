import { Dialog } from "@progress/kendo-react-dialogs";
import { ITenantBasic } from "../../../../global/interfaces/ITenantBasic";
import usePostNewTenantQuery from "../../queries/usePostNewTenantQuery";
import { ITenant } from "../../../../global/interfaces/ITenant";
import TenantForm from "../TenantForm";
import { SubmitTenantData } from "../../../../global/types/SubmitTenantData";

interface IProps {
  handleCloseDialog: () => void;
  hanldeSuccessfulCreation: () => void;
}

const AddTenantDialog = (props: IProps) => {
  const postNewTenantQuery = usePostNewTenantQuery();

  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  const handleSubmit = async (data: SubmitTenantData) => {
    const newTenantCandidate = data as ITenantBasic;
     postNewTenantQuery.mutate(newTenantCandidate, {
      onSuccess: (newTenant: ITenant) => {
        props.handleCloseDialog();
      },
    });
  };

  const initialTenantValues: ITenantBasic = {
  webserverUrl: "",
  name: "",
  description: "",
  lizenzname: "",
};
  return (
    <>
      <Dialog
        width={400}
        title={"Neues Unternehmen erstellen"}
        onClose={handleCloseDialog}
      >
        <TenantForm
          handleSubmit={handleSubmit}
          handleCancel={handleCloseDialog}
          tenantBasic={initialTenantValues}
          isNewTenant={true}
        />
      </Dialog>

    </>
  );
};

export default AddTenantDialog;
