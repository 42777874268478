import React from "react";
import { IAccountUserInfo } from "../../interfaces/IAccountUserInfo";

interface IProps {
  accountUserInfo: IAccountUserInfo;
}

// displays the name of the user logged in via the account
// if there is a first or last name, then display that (or ideally both)
// otherwise display the email of the associated logged in user account
const WelcomeName = (props: IProps) => {
  const getName = () => {

    if (props.accountUserInfo) {
      let username;

      // case no first or last name set => diplay email
      // if(!props.accountUserInfo.firstName && !props.accountUserInfo.lastName){
      //     return  props.accountUserInfo.email;

      // handle name

      // case first and last name are set => display both
      // } else if(props.accountUserInfo.firstName && props.accountUserInfo.lastName){
      if (props.accountUserInfo.firstName && props.accountUserInfo.lastName) {
        username =
          props.accountUserInfo.firstName +
          " " +
          props.accountUserInfo.lastName;

        // case only first name is set => display first name only
      } else if (
        props.accountUserInfo.firstName &&
        !props.accountUserInfo.lastName
      ) {
        username = props.accountUserInfo.firstName;

        // case only last name is set => display last name only
      } else if (
        !props.accountUserInfo.firstName &&
        props.accountUserInfo.lastName
      ) {
        username = props.accountUserInfo.lastName;
      }

      // hanlde email
      // case the username is defiend, i.e. there is a first and/or last name
      if (username) {
        username = username + " (" + props.accountUserInfo.email + ")";
      } else {
        // case no first or last name set => display email only
        username = props.accountUserInfo.email;
      }

      return username;
    } else {
      return undefined;
    }
  };

  const userName = getName();

  return (
    <>
      {userName && <span>, </span>}
      <br />
      {userName && <span>{userName}</span>}
    </>
  );
};

export default WelcomeName;
