import React from "react";
import { IAssociatedTenant } from "../../interfaces/IAssociatedTenant";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";

interface IPropsRedirectButton {
  selectedTenant?: IAssociatedTenant;
  onClick: () => void;
}

const RedirectButton = ({ selectedTenant, onClick }: IPropsRedirectButton) => {
  const tooltip = React.useRef(null);
  
  return (
    <div className="right-align-button">
            {selectedTenant ? (
              <Button
                className="portal-button-green"
                onClick={onClick}
              >
                Weiter
              </Button>
            ) : (
              <>
                <Tooltip ref={tooltip} anchorElement="target" position="top">
                  <div title="Sie müssen zuert einen Tenant auswählen">
                    <Button disabled={true}>Weiter</Button>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
  );
};

export default RedirectButton;
