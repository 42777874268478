import { useMutation } from "react-query"
import { IApiError } from "../../../global/interfaces/IApiError"
import { DELETE_DELETE_API_KEY } from "../../../global/utils/Constants"
import axios from "axios"

const useDeleteApiKeyQuery = () => {
    return useMutation<void, IApiError, string>(async (id: string) =>
        await axios.delete(DELETE_DELETE_API_KEY + "?id=" + id), {}
    )
}

export default useDeleteApiKeyQuery;