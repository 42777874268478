
import { GridCellProps } from "@progress/kendo-react-grid";
import { useState } from "react";
import DeleteIcon from "../../../../global/icons/DeleteIcon";
import { IAccountBasic } from "../../../../global/interfaces/IAccountBasic";
import { Button } from "@progress/kendo-react-buttons";
import DeleteAssociationDialog from "./DeleteAssociationDialog";

interface IProps {
    gridCellProps: GridCellProps;
    handleDeleteAssociationDone: (accountId: string) => void;
    accountBasic:IAccountBasic;
    tenantId: string;
  }

const DeleteAssociationAction = (props: IProps) =>{
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const accountBasic = props.accountBasic;

    const toggleDialog = () => {
        setShowDialog((prevShowDialog) => !prevShowDialog);
      };
    
      const handleCloseDialog = () => {
        props.handleDeleteAssociationDone(accountBasic.email);
        toggleDialog();
      };

      return (
        <>
          <span className="k-command-cell">
            <Button className="no-border" onClick={toggleDialog}>
              <DeleteIcon  className={"table-icon"} />
            </Button>
            {showDialog && (
              <DeleteAssociationDialog
                accountBasic={accountBasic}
                handleCloseDialog={handleCloseDialog}
                tenantId={props.tenantId}
              />
            )}
          </span>
        </>
      );

}

export default DeleteAssociationAction;