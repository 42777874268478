import editIcon from "./Edit.Edit.svg";

interface IProps {
  style?: React.CSSProperties;
  className?: string;
}

const EditIcon = (props: IProps) => {
  return (
    <img
      src={editIcon}
      alt="editIcon"
      style={props.style}
      className={props.className}
    />
  );
};

export default EditIcon;
