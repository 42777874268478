import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IAccount } from "../../../../global/interfaces/IAccount";
import useDeleteAccountQuery from "../../queries/useDeleteAccountQuery";

interface IProps {
  handleDeleteDone: () => void;
  account: IAccount;
  handleCancelDialog: () => void;
}

const DeleteAccountDialog = (props: IProps) => {
  const deleteAccountQuery = useDeleteAccountQuery();

  const handleDeleteAccount = () => {
    deleteAccountQuery.mutate(props.account.email, {
      onSuccess: () => {
        props.handleDeleteDone();
      },
    });
  };

  const handleCancelDialog = () => {
    props.handleCancelDialog();
  };

  return (
    <>
      <Dialog
        title={"Benutzerkonto Löschen"}
        onClose={handleCancelDialog}
        width={400}
      >
        <p>
          Wollen Sie dieses Benutzerkonto ({props.account.email}) wirklich löschen?
        </p>
        <DialogActionsBar>
          <Button onClick={handleCancelDialog}>Nein</Button>
          <Button onClick={handleDeleteAccount}>Ja</Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DeleteAccountDialog;
