import React from 'react';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
// import { Fade } from "@progress/kendo-react-animation";

const SuccessNotificationToast = ({ show, message, onClose }) => {
    return (
        <NotificationGroup
            style={{
                right: 0,
                bottom: 0,
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
            }}
        >
            <div>
                {show && (
                    <Notification
                        type={{
                            style: "success",
                            icon: true,
                        }}
                        closable={true}
                        onClose={onClose}
                    >
                        <span>{message}</span>
                    </Notification>
                )}
            </div>
        </NotificationGroup>
    );
};

export default SuccessNotificationToast;