import { Dialog } from "@progress/kendo-react-dialogs";

import { useSelector } from "../global/stateManagement/hooks";

import {
  selectLoggedIn,
} from "../global/stateManagement/authSlice";
import {
  selectSessionExpired,
} from "../global/stateManagement/sessionSlice";
import MicrosoftLoginButton from "../pages/login/components/MicrosoftLoginButton";

const UnauthorizedDialog = () => {

  const loggedIn = useSelector(selectLoggedIn);
  const sessionExpired = useSelector(selectSessionExpired);

  return (
    <>
      {loggedIn && sessionExpired && (
        <Dialog title={"Session ist abgelaufen"}
        closeIcon={false}
        >
          <p>
            Ihre Session ist abgelaufen. Um weiter arbeiten zu können müssen Sie
            sich erneut einloggen
          </p>
          <div className="portal-div-to-center">
            <MicrosoftLoginButton currentPath={window.location.pathname}/>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UnauthorizedDialog;
