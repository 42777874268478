import React, { useCallback, useEffect, useState } from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { AbkEventEmitter } from "../utils/AbkEventEmitter";
import { EVENT_BE_ERROR_OCCURED } from "../utils/Constants";
// import { Fade } from "@progress/kendo-react-animation";

const ErrorNotificationToast = () => {
  const [messages, setMessages] = useState<string[]>([]);

  const addNotification = useCallback((message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  useEffect(() => {
    const handleError = (errorMessage) => {
      addNotification(errorMessage);
    };

    AbkEventEmitter.on(EVENT_BE_ERROR_OCCURED, handleError);

    return () => {
      AbkEventEmitter.off(EVENT_BE_ERROR_OCCURED, handleError);
    };
  }, [addNotification]);

  return (
    <NotificationGroup
      style={{
        right: 0,
        bottom: 0,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
      }}
    >
      <div>
        {messages &&
          messages.map((message, index) => (
            <Notification
              key={index}
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() =>
                setMessages((prevMessages) =>
                  prevMessages.filter((msg) => msg !== message)
                )
              }
            >
              <span>{message}</span>
            </Notification>
          ))}
      </div>
    </NotificationGroup>
  );
};

export default ErrorNotificationToast;
