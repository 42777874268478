import infoIcon from "./Info.png";

interface IProps {
  style?: React.CSSProperties;
  className?: string;
}

const InfoIcon = (props: IProps) => {
  return (
    <img
      src={infoIcon}
      alt="infoIcon"
      style={props.style}
      className={props.className}
    />
  );
};

export default InfoIcon;
