import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import AddApiKeyDialog from "./AddApiKeyDialog";

interface IProps {
  handleAddApiKeyDone: () => void;
}

const AddApiKey = (props: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleCloseDialog = () => {
    props.handleAddApiKeyDone();
    toggleDialog();
  };

  return (
    <>
      <Button title="Neuen API Key erstellen" onClick={toggleDialog}>
        Neuen API Key erstellen
      </Button>

      {showDialog && <AddApiKeyDialog handleCloseDialog={handleCloseDialog} />}
    </>
  );
};

export default AddApiKey;
