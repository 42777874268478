import { IAccountBasic } from "../../../../global/interfaces/IAccountBasic";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import useDeleteAssociationQuery from "../../queries/useDeleteAssociationQuery";
import { ITenantAccountAssociation } from "../../interfaces/ITenantAccountAssociation";

interface IProps {
  handleCloseDialog: () => void;
  accountBasic: IAccountBasic;
  tenantId: string;
}

const DeleteAssociationDialog = (props: IProps) => {
  const deleteTenantAccountAssociation = useDeleteAssociationQuery();

  const handleDeleteTenantAccountAssociation = () => {
    const requestParam: ITenantAccountAssociation = {
      email: props.accountBasic.email,
      webserverUrl: props.tenantId,
    };
    deleteTenantAccountAssociation.mutate(requestParam, {
      onSuccess: () => {
        props.handleCloseDialog();
      },
    });
  };

  return (
    <>
      <Dialog
        title={"Zugehörigkeit Löschen"}
        onClose={props.handleCloseDialog}
        width={400}
      >
        <p>
          Wollen Sie dieses Benutzerkonto ({props.accountBasic.email}) wirklich zur
          Zugehörigkeit mit dem Unternehmen löschen?
        </p>
        <DialogActionsBar>
          <Button onClick={props.handleCloseDialog}>Nein</Button>
          <Button onClick={handleDeleteTenantAccountAssociation}>Ja</Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DeleteAssociationDialog;
