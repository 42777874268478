import { GridCellProps } from "@progress/kendo-react-grid";
import Roles from "../../../../global/enums/Roles";

const RollenCell = (props: GridCellProps) => {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.roles &&
        dataItem.roles.map((role: Roles, index) => (
          <div key={index}>{role}</div>
        ))}
    </td>
  );
};

export default RollenCell;
