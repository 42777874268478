import { Dialog } from "@progress/kendo-react-dialogs";
import { ICreateApiKey } from "../../interfaces/ICreateApiKey";
import { useState } from "react";
import usePostNewApiKeyQuery from "../../queries/usePostNewApiKeyQuery";
import { IApiKey } from "../../interfaces/IApiKey";
import AddApiKeyForm from "./AddApiKeyForm";
import NewApiKeyInfo from "./NewApiKeyInfo";

interface IProps {
  handleCloseDialog: () => void;
}
const AddApiKeyDialog = (props: IProps) => {
  const [newApiKeyValue, setNewApiKeyValue] = useState<string | null>(null);

  const postNewApiKeyQuery = usePostNewApiKeyQuery();

  const handleCloseDialog = () => {
    setNewApiKeyValue(null);
    props.handleCloseDialog();
  };

  const handleSubmit = async (values: { [name: string]: any }) => {
    const newApiKeyCandidate: ICreateApiKey = {
      title: values.title,
      description: values.description,
    };

    postNewApiKeyQuery.mutate(newApiKeyCandidate, {
      onSuccess: (newApiKeyRes: IApiKey) => {
        setNewApiKeyValue(newApiKeyRes.apikeyValue);
      },
    });
  };

  return (
    <>
      <Dialog
        title={"Neuen Api Key erstellen"}
        onClose={handleCloseDialog}
        width={400}
      >
        {newApiKeyValue ? (
          <NewApiKeyInfo
            apiKeyValue={newApiKeyValue}
            handleCloseDialog={handleCloseDialog}
          />
        ) : (
          <AddApiKeyForm
            handleSubmit={handleSubmit}
            handleCloseDialog={handleCloseDialog}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddApiKeyDialog;
