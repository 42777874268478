import { IAccount } from "../../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";
import usePostNewAccountQuery from "../../../queries/usePostNewAccountQuery";
import AccountCreateUpdateForm from "./AccountCreateUpdateForm";

interface AccountCreateFormProps {
  onSubmitDone: (values: { [key: string]: any }) => void;
  handleCancelDialog: () => void;
  allTenants: ITenant[];
}

const AccountCreateForm = ({
  onSubmitDone,
  handleCancelDialog,
  allTenants,
}: AccountCreateFormProps) => {
  const postNewAccountQuery = usePostNewAccountQuery();

  const initialAccountValues: IAccount = {
    email: "",
    latestUsedTenant: null,
    isAlwaysRedirectToLatestUsedTenant: false,
    roles: [],
    lastLogin: null,
    tenants: [],
  };

  const onSubmit = (formValues: { [name: string]: any }) => {
    const newAccount: IAccount = {
      email: formValues.email,
      latestUsedTenant: null,
      isAlwaysRedirectToLatestUsedTenant: false,
      roles: formValues.roles,
      lastLogin: null,
      tenants: formValues.tenants,
    };

    postNewAccountQuery.mutate(newAccount, {
      onSuccess: (newSavedAccount: IAccount) => {
        onSubmitDone(newSavedAccount);
      },
    });
  };

  return (
    <AccountCreateUpdateForm
      account={initialAccountValues}
      allTenants={allTenants}
      mode={AccountFormMode.Create}
      handleCancelDialog={handleCancelDialog}
      onSubmit={onSubmit}
    />
  );
};

export default AccountCreateForm;
