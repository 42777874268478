import {
  GridColumn as Column,
  Grid,
  GridToolbar,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { IAccountBasic } from "../../../../global/interfaces/IAccountBasic";
import { ITenant } from "../../../../global/interfaces/ITenant";
import useGetAllAssociatedAccountsQuery from "../../queries/useGetAllAssociatedAccountsQuery";
import TenantAccountAssociationActionsCell from "./TenantAccountAssociationActionsCell";
import AddAccountToTenant from "./addAccountToTenant/AddAccountToTenant";
import DateCell from "../../../../global/components/tableCells/DateCell";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

interface IProps {
  tenant: ITenant;
}
const DATA_ITEM_KEY: string = "email";

const AssociatedAccountsTable = ({ tenant }: IProps) => {
  const tenantAccounts: IAccountBasic[] = tenant.accounts;
  const [accounts, setAccounts] = useState<IAccountBasic[]>(tenantAccounts);

  const initialSort: Array<SortDescriptor> = [
    { field: DATA_ITEM_KEY, dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const {
    refetch: refetchAllAssociatedAccounts,
    data: allFetchedAllAssociatedAccounts,
    isFetched: areAllAssociatedAccountsFetched,
    isError: isErrorGetAllAssociatedAccounts,
    error: errorGetAllAssociatedAccounts,
  } = useGetAllAssociatedAccountsQuery(tenant.webserverUrl);

  const removeAccountFromTenant = (email: string) => {
    const updatedAccountsList = accounts.filter(
      (account) => account.email !== email
    );
    setAccounts(updatedAccountsList);
    refetchAllAssociatedAccounts();
  };

  useEffect(() => {
    if (areAllAssociatedAccountsFetched) {
      if (allFetchedAllAssociatedAccounts) {
        setAccounts(allFetchedAllAssociatedAccounts);
      } else {
        setAccounts([]);
      }
    }
  }, [areAllAssociatedAccountsFetched, allFetchedAllAssociatedAccounts]);

  return (
    <>
      {accounts ? (
        <Grid
          data={orderBy(accounts, sort)}
          style={{
            maxHeight: "400px",
          }}
          dataItemKey={DATA_ITEM_KEY}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <AddAccountToTenant
              handleAddAccountToTenantDone={refetchAllAssociatedAccounts}
              tenantId={tenant.webserverUrl}
            />
          </GridToolbar>
          <Column field="email" title="Email" editable={false} />
          <Column
            field="lastLogin"
            title="Zuletzt Angemeldet"
            editable={false}
            cell={DateCell}
          />
          <Column
            field="actions"
            title="Aktionen"
            cell={(props) => (
              <TenantAccountAssociationActionsCell
                gridCellProps={props}
                handleDeleteAccountAssociationDone={removeAccountFromTenant}
                tenantId={tenant.webserverUrl}
              />
            )}
          />
        </Grid>
      ) : (
        <p>Keine Daten gefunden.</p>
      )}
    </>
  );
};
export default AssociatedAccountsTable;
