import axios, { AxiosError } from "axios"
import { useMutation } from "react-query"
import { TENANT_ASSOCIATION } from "../../../global/utils/Constants"
import { ITenantAccountAssociation } from "../interfaces/ITenantAccountAssociation"


const usePostNewAssociationQuery = () => {
    return useMutation<void, AxiosError, ITenantAccountAssociation>(async (newAssociation: ITenantAccountAssociation) =>
        await axios.post(TENANT_ASSOCIATION, 
            newAssociation, 
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.data),
        {
        }
    );

}

export default usePostNewAssociationQuery;