import React from "react";
import  msLogo from "../images/microsoftLogo.png"

const MicrosoftLogo = () => {

    return <img
    src={msLogo}
    alt="Microsoft Logo"
    style={{
      width: '20px',  
      height: '20px',
      marginRight: '8px',  
    }}
  />
};

export default MicrosoftLogo;