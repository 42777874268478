import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssociatedTenants } from '../../pages/login/interfaces/IAssociatedTenants';

// Define a type for the slice state
interface AssoicatedTenantsSlice {
    associatedTenants: IAssociatedTenants | null
}

// Define the initial state using that type
const initialState: AssoicatedTenantsSlice = {
    associatedTenants: null
}

const associatedTenantsSlice = createSlice({
    name: 'associatedTenants',
    initialState,
    reducers: {
        setAssociatedTenants: (state, action: PayloadAction<IAssociatedTenants>) => {
            state.associatedTenants = action.payload;
        }
    }
})

export const { setAssociatedTenants } = associatedTenantsSlice.actions;
export const selectAssociatedTenants = (state: { associatedTenants: AssoicatedTenantsSlice }) => state.associatedTenants.associatedTenants;
export default associatedTenantsSlice.reducer;