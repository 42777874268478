import { Button } from "@progress/kendo-react-buttons";
import useGetRandomBeErrorQuery from "../../../../global/queries/useGetRandomBeErrorQuery";

const BeErrorTest = () => {
  const { refetch: fetchRandomError } = useGetRandomBeErrorQuery();

  return <Button onClick={() => fetchRandomError()}>Fetch an error</Button>;
};

export default BeErrorTest;
