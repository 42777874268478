import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccountUserInfo } from '../../pages/login/interfaces/IAccountUserInfo';
import { RootState } from './store';
import Roles from '../enums/Roles';

// Define a type for the slice state
interface UserState {
    user: IAccountUserInfo | null
}

// Define the initial state using that type
const initialState: UserState = {
    user: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IAccountUserInfo | null>) => {
            state.user = action.payload;
        },
        updateUser: (state, action: PayloadAction<Partial<IAccountUserInfo>>) => {
            if (state.user) {
                state.user = { ...state.user, ...action.payload };
            }
        },
        addUserRole: (state, action: PayloadAction<Roles>) => {
            if (state.user && state.user.roles === undefined) {
                state.user.roles = [];
            }
            if (state.user && state.user.roles) {
                state.user.roles.push(action.payload);
            }
        },
        removeUserRole: (state, action: PayloadAction<Roles>) => {
            if (state.user && state.user.roles) {
                const i = state.user.roles.indexOf(action.payload);
                state.user.roles.splice(i, 1);
            }
        },
        clearUser: (state) => {
            state.user = null;
        },
    }
})
// Export actions
export const { setUser, updateUser, addUserRole, removeUserRole, clearUser } = userSlice.actions;

// Define selectors
export const selectUser = (state: RootState): IAccountUserInfo | null => state.user.user;
export const selectUserRoles = (state: RootState): Roles[] | undefined => state.user.user?.roles;
export const selectIsUserPortalAdmin = (state: RootState): boolean => {
    if (state.user && state.user.user && state.user.user.roles) {
        return state.user.user.roles.includes(Roles.PORTAL_ADMIN);
    }
    return false;
}

export default userSlice.reducer;