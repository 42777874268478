import { Error } from "@progress/kendo-react-labels";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  selectLoggedIn,
  setLoggedIn,
} from "../../global/stateManagement/authSlice";
import { useDispatch, useSelector } from "../../global/stateManagement/hooks";
import {
  CREATE_SESSION_URL,
  NAV_PATH_TENANT_SELECTION,
} from "../../global/utils/Constants";
import "./LoginPage.scss";
import useCheckLoginQuery from "./queries/useCheckLoginQuery";
import useGetAssociatedTenantsQuery from "./queries/useGetAssociatedTenantsQuery";

import LoginWindowLayout from "../../global/components/LoginWindowLayout";
import { setSessionExpired } from "../../global/stateManagement/sessionSlice";
import { setUser } from "../../global/stateManagement/userSlice";
import WelcomeComponent from "./components/WelcomeComponent";
import { IAssociatedTenants } from "./interfaces/IAssociatedTenants";
import { setAssociatedTenants } from "../../global/stateManagement/associatedTenantsSlice";

const LoginPage = (props) => {
  // Use Redux hooks and actions for the global loggedIn variable
  const loggedIn = useSelector(selectLoggedIn);
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState<string | null>(null);
  let errorTag;
  const navigate = useNavigate();

  const redirectUrlCheckLogin =
    "https://localhost/api/login/validateLogin/redirect";
  const { isError: isCheckLoginError, error: checkLoginError } =
    useCheckLoginQuery(redirectUrlCheckLogin);

  const {
    isError: isErrorGetAllTenants,
    data: associatedTenants,
    error: errorGetAllAccounts,
    isFetched: areAssociatedTenantsFetched,
  } = useGetAssociatedTenantsQuery();

  const setUserAsLoggedIn = useCallback(() => {
    console.log("LoginPage setUserAsLoggedIn");
    dispatch(setLoggedIn(true));
    dispatch(setSessionExpired(false));
    const userPathBeforeLogin = localStorage.getItem("pathBeforeLogin");

    // case login happend because session exipred and we need to redirect to the page where the user left off
    if (
      userPathBeforeLogin &&
      userPathBeforeLogin !== window.location.pathname
    ) {
      localStorage.removeItem("pathBeforeLogin");

      navigate(userPathBeforeLogin
        // , { replace: true }
      );
    } else {
      // normal login process -> navigate to tenant selection page
      navigate(NAV_PATH_TENANT_SELECTION, {
        state: {
          latestUsedTenant: associatedTenants?.latestUsedTenant,
          isAlwaysRedirectToLatestUsedTenant:
            associatedTenants?.isAlwaysRedirectToLatestUsedTenant,
          tenants: associatedTenants?.tenants,
        },
        // replace: true,
      });
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    console.log(
      "LoginPage useEffect areAssociatedTenantsFetched: " +
        areAssociatedTenantsFetched +
        " associatedTenants: "
    );
    console.log(associatedTenants);
    if (areAssociatedTenantsFetched && associatedTenants) {
      if (
        associatedTenants?.isAlwaysRedirectToLatestUsedTenant &&
        associatedTenants?.latestUsedTenant !== null
      ) {
        // case that isAlwaysRedirectToLatestUsedTenant is set to true and there is a latestUsedTenant set for the logged in user
        // hence, immeadiately redirect to the latestUsedTenant
        // @ts-ignore
        window.location =
          CREATE_SESSION_URL + associatedTenants.latestUsedTenant.webserverUrl;
      } else {
        // case tenant selection should be shown
        setUserAsLoggedIn();
        // save the associatedTenants in the store so TenantSelectionPage can use them
        console.log("LoginPafe saving associatedTenants in store: ");
        console.log(associatedTenants);

        dispatch(setAssociatedTenants(associatedTenants));
      }
    }
  }, [areAssociatedTenantsFetched, associatedTenants, setUserAsLoggedIn]);

  useEffect(() => {
    if (isErrorGetAllTenants) {
      if (errorGetAllAccounts.response?.status === 401) {
        // case the user is not yet logged in while loading the associated tenants from the database
        dispatch(setLoggedIn(false));
      } else {
        // another error occurred which should be displayed
        setGeneralError(JSON.stringify(errorGetAllAccounts));
      }
    }
  }, [isErrorGetAllTenants, errorGetAllAccounts, dispatch]);

  useEffect(() => {
    console.log(
      "in useEffect of isCheckLoginError, checkLoginError; isCheckLoginError: " +
        isCheckLoginError +
        " checkLoginError(in next line):"
    );
    console.log(checkLoginError);
    if (isCheckLoginError) {
      if (axios.isAxiosError(checkLoginError)) {
        if (checkLoginError.response?.status === 403) {
          console.log("You reached 403 for the checkLogin button.");
        }
      }
    }
  }, [isCheckLoginError, checkLoginError]);

  useEffect(() => {
    if (!loggedIn) {
      // If the user is not logged in, set the user state to null
      console.log("logged out - setting user to null");
      dispatch(setUser(null));
    }
  }, [loggedIn, dispatch]);

  if (generalError) {
    errorTag = <Error>{generalError}</Error>;
  }

  const handleLogout = () => {
    dispatch(setLoggedIn(false));
  };

  return (
    <>
      <LoginWindowLayout
        loggedIn={loggedIn}
        onLogout={handleLogout}
      >
        <WelcomeComponent />
      </LoginWindowLayout>
    </>
  );
};

export default LoginPage;
