import { GridCellProps } from "@progress/kendo-react-grid";
import { ITenantBasic } from "../../../../global/interfaces/ITenantBasic";

const AssociatedTenantsCell = (props: GridCellProps) => {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.tenants && dataItem.tenants.map((tenant:ITenantBasic, index) => (
        <div key={index}>{tenant.webserverUrl}</div>
      ))}
    </td>
  );
};

export default AssociatedTenantsCell;
