import { GridCellProps } from "@progress/kendo-react-grid";
import moment from "moment";

const ExpiryDateColumn = (props: GridCellProps) => {
  const dataItem = props.dataItem;

  if (dataItem && dataItem.expiryDate !== undefined) {
    console.log();
    return (
      <td>
        {moment(Date.parse(props.dataItem.expiryDate)).format("DD.MM.YYYY")}
      </td>
    );
  }
  return  <td></td>;
};

export default ExpiryDateColumn;
