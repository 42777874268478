import axios, { AxiosError } from "axios"
import { useMutation } from "react-query"
import { TENANT_ASSOCIATION } from "../../../global/utils/Constants"
import { ITenantAccountAssociation } from "../interfaces/ITenantAccountAssociation"


const useDeleteAssociationQuery = () => {
    return useMutation<void, AxiosError, ITenantAccountAssociation>(async (newAssociation: ITenantAccountAssociation) =>
        await axios.delete(TENANT_ASSOCIATION,
            {
                data: newAssociation,
                headers: { 'Content-Type': 'application/json' }
            },
        )
            .then(res => res.data),
        {
        }
    );

}

export default useDeleteAssociationQuery;