import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoginWindowLayout from "../../global/components/LoginWindowLayout";
import { selectAssociatedTenants } from "../../global/stateManagement/associatedTenantsSlice";
import { selectLoggedIn } from "../../global/stateManagement/authSlice";
import { selectUser } from "../../global/stateManagement/userSlice";
import TenantSelection from "../login/components/tenantSelection/TenantSelection";
import { IAssociatedTenants } from "../login/interfaces/IAssociatedTenants";
import useGetAssociatedTenantsManuallyQuery from "../login/queries/useGetAssociatedTenantsManuallyQuery";

const TenantSelectionPage = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const associatedTenantsFromStore = useSelector(selectAssociatedTenants);
  const location = useLocation();
  const [initLoaded, setInitLoaded] = useState<boolean>(false);

  const [associatedTenants, setAssociatedTenants] =
    useState<IAssociatedTenants>();

  const {
    isError: isErrorGetAllTenants,
    data: fetchedAssociatedTenants,
    error: errorGetAllAccounts,
    isFetched: areAssociatedTenantsFetched,
    refetch: fetchAssoicatedTenants,
  } = useGetAssociatedTenantsManuallyQuery();

  useEffect(() => {
    if (location.state && location.state.tenants !== undefined) {
      // if associated tenants were passed vie navigation
      const passedAssocaitedTenants = location.state as IAssociatedTenants;
      setAssociatedTenants(passedAssocaitedTenants);
    } else if (associatedTenantsFromStore !== null) {
      // if assoicated Tenants are already in store
      setAssociatedTenants(associatedTenantsFromStore);
    } else {
      // fetch assocciated tenants from BE
      fetchAssoicatedTenants();
    }
  }, [location, associatedTenantsFromStore, fetchAssoicatedTenants]);

  useEffect(() => {
    if (areAssociatedTenantsFetched && !isErrorGetAllTenants) {
      setAssociatedTenants(fetchedAssociatedTenants);
    }
  }, [areAssociatedTenantsFetched, isErrorGetAllTenants]);

  useEffect(() => {
    if (associatedTenants) {
      setInitLoaded(true);
    } else {
      setInitLoaded(false);
    }
  }, [associatedTenants]);

  return (
    <>
      <LoginWindowLayout loggedIn={loggedIn} initLoaded={initLoaded}>
        <TenantSelection tenants={associatedTenants!} accountUserInfo={user!} />
      </LoginWindowLayout>
    </>
  );
};

export default TenantSelectionPage;
