import { createBrowserRouter } from "react-router-dom";
// import LoginPage from "../../pages/login/LoginPageOld";

import NoPermission from "../../pages/noPermission/NoPermission";
import Settings from "../../pages/settings/Settings";
import AccountManagement from "../../pages/tenantManagement/AccountsManagement";
import TenantDetail from "../../pages/tenantManagement/tenantDetail/TenantDetail";
import TenantManagement from "../../pages/tenantManagement/TenantManagement";
import Roles from "../enums/Roles";
import {
  NAV_PATH_ACCOUNTS_MANAGEMENT,
  NAV_PATH_PERMISSION_DENIED,
  NAV_PATH_TENANTS_MANAGEMENT,
  NAV_PATH_TENANT_DETAIL_MANAGEMENT,
  NAV_PATH_TENANT_SELECTION,
} from "../utils/Constants";
import ProtectedRoute from "./ProtectedRoute";
import TenantSelectionPage from "../../pages/tenantSelection/TenantSelectionPage";
import LoginPage from "../../pages/login/LoginPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <TenantSelectionPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: NAV_PATH_TENANT_SELECTION,
    element: (
      <ProtectedRoute>
        <TenantSelectionPage />
      </ProtectedRoute>
    ),
  },
  {
    path: NAV_PATH_PERMISSION_DENIED,
    element: <NoPermission />,
  },
  {
    path: "/einstellungen",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  
  {
    path: NAV_PATH_TENANTS_MANAGEMENT,
    element: (
      <ProtectedRoute
        requiredRoles={[Roles.PORTAL_ADMIN]}
        redirectPath={NAV_PATH_PERMISSION_DENIED}
      >
        <TenantManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: NAV_PATH_TENANT_DETAIL_MANAGEMENT,
    element: (
      <ProtectedRoute
        requiredRoles={[Roles.PORTAL_ADMIN]}
        redirectPath={NAV_PATH_PERMISSION_DENIED}
      >
        <TenantDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: NAV_PATH_ACCOUNTS_MANAGEMENT,
    element: (
      <ProtectedRoute
        requiredRoles={[Roles.PORTAL_ADMIN]}
        redirectPath={NAV_PATH_PERMISSION_DENIED}
      >
        <AccountManagement />
      </ProtectedRoute>
    ),
  },
]);
