import { useQuery } from "react-query";
import axios from "axios";
import { GET_VALIDATE_NEW_EMAIL } from "../../../global/utils/Constants";
import { IValidationError } from "../../../global/interfaces/IValidationError";


const useGetValidateNewAccountEmailQuery = (email: string) => {
    return useQuery({
        queryKey: ["ValidateNewAccountEmail"],
        queryFn: () => axios
            .get<IValidationError>(GET_VALIDATE_NEW_EMAIL + email)
            .then(res => res.data),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
    })
}
export default useGetValidateNewAccountEmailQuery;