import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { ITenant } from "../../../global/interfaces/ITenant";
import { ITenantBasic } from "../../../global/interfaces/ITenantBasic";
import {
  LOCAL_STORAGE_TENANT_DETAILS_KEY,
  NAV_PATH_TENANTS_MANAGEMENT,
} from "../../../global/utils/Constants";
import BasicTenantDetails from "./BasicTenantDetails";
import AssociatedAccountsTable from "./associatedAccountsTable/AssociatedAccountsTable";

const TenantDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [tenant, setTenant] = useState<ITenant | null>(null);

  useEffect(() => {
    if (location.state) {
      setTenant(location.state as ITenant);
    } else {
      const storedTenant = localStorage.getItem(
        LOCAL_STORAGE_TENANT_DETAILS_KEY
      );

      if (storedTenant) {
        setTenant(JSON.parse(storedTenant));
      } else {
        navigate(NAV_PATH_TENANTS_MANAGEMENT);
      }
    }
  }, [location, navigate]);

  if (!tenant) {
    // show a loading indicator or message while redirecting - necessary for null check to initialize tenantBasic without nullpointer
    return <div>Loading...</div>;
  }

  const tenantBasic: ITenantBasic = {
    webserverUrl: tenant.webserverUrl,
    name: tenant.name,
    description: tenant.description,
    lizenzname: tenant.lizenzname,
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
      Unternehmen Details - {tenantBasic.webserverUrl}
      </h1>
      <BasicTenantDetails tenantBasic={tenantBasic} />

      <div className="container-allDataTable portal-small-margin-top">
        <AssociatedAccountsTable tenant={tenant} />
      </div>
    </div>
  );
};

export default TenantDetail;
