import axios from "axios";
import { useQuery } from "react-query";
import { GET_VALIDATE_LIZENZNAME_OF_TENANT } from "../../../global/utils/Constants";
import { IValidationError } from "../../../global/interfaces/IValidationError";


const useGetValidateLizenzname = (lizenzname: string) => {
    return useQuery({
        queryKey: ["ValidateLizenzname"],
        queryFn: () => axios
            .get<IValidationError>(GET_VALIDATE_LIZENZNAME_OF_TENANT + encodeURI(lizenzname))
            .then(res => res.data),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
    })
}
export default useGetValidateLizenzname;