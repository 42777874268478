import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface SessionState {
    sessionExpired: boolean
}

// Define the initial state using that type
const initialState: SessionState = {
    sessionExpired: false
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionExpired: (state, action: PayloadAction<boolean>) => {
            state.sessionExpired = action.payload;
        }
    }
})

export const { setSessionExpired } = sessionSlice.actions;
export const selectSessionExpired = (state: { session: SessionState }) => state.session.sessionExpired;
export default sessionSlice.reducer;