import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITenant } from '../interfaces/ITenant';

// Define a type for the slice state
interface TenantDetailState {
    tenant: ITenant | null
}

// Define the initial state using that type
const initialState: TenantDetailState = {
    tenant: null
}

const tenantDetailSlice = createSlice({
    name: 'tenantDetail',
    initialState,
    reducers: {
        setTenantDetail: (state, action: PayloadAction<ITenant>) => {
            state.tenant = action.payload;
        }
    }
})

export const { setTenantDetail } = tenantDetailSlice.actions;
export const selectTenantDetail = (state: { tenantDetail: TenantDetailState }) => state.tenantDetail.tenant;
export default tenantDetailSlice.reducer;