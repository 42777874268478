import { GridCellProps } from "@progress/kendo-react-grid";
import DeleteTenantAction from "./deleteTenant/DeleteTenantAction";
import UpdateTenantAction from "./updateTenant/UpdateTenantAction";

interface IProps {
  handleDeleteDone: (webserverUrl: string) => void;
  gridCellProps: GridCellProps;
}

const TenantActionsCell = (props:IProps) => {
  const handleDeleteTenantDone = (webserverUrl: string) => {
    props.handleDeleteDone(webserverUrl);
  };

  return (
    <>
    <td>
    <DeleteTenantAction
        gridCellProps={props.gridCellProps}
        handleDeleteTenantDone={handleDeleteTenantDone}
        dataItem={props.gridCellProps.dataItem}
      />
      <UpdateTenantAction
        gridCellProps={props.gridCellProps}
        currentTenant={props.gridCellProps.dataItem}
      />
    </td>
     
    </>
  );
};

export default TenantActionsCell;
