import React from "react";

const LoginFooter = () =>(
<div className="copyright-container space-between-container">
                  <a
                    href="https://www.abk.at/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Help?
                  </a>
                  <a
                    href="https://www.abk.at/impressum/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "auto" }}
                  >
                    Impressum
                  </a>
                  <a
                    href="https://www.abk.at/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "10px" }}
                  >
                    Datenschutz
                  </a>
                </div>
);

export default LoginFooter;
