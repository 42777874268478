import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { GET_ASSOCIATED_TENANTS_URL } from "../../../global/utils/Constants";
import { IAssociatedTenants } from "../interfaces/IAssociatedTenants";

const useGetAssociatedTenantsManuallyQuery = () => {
    return useQuery({
        queryKey: ["getAssociatedTenants"],
        queryFn: () => axios
            .get<IAssociatedTenants>(GET_ASSOCIATED_TENANTS_URL)
            .then(res => res.data),
        onError: (err: AxiosError) => err,
        retry: 1,
        enabled: false,
        refetchOnWindowFocus: false,
    })
}

export default useGetAssociatedTenantsManuallyQuery;