import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../../global/icons/EditIcon";
import { ITenant } from "../../../../global/interfaces/ITenant";
import {
  LOCAL_STORAGE_TENANT_DETAILS_KEY,
  NAV_PATH_TENANT_DETAIL_MANAGEMENT,
} from "../../../../global/utils/Constants";

interface IProps {
  gridCellProps: GridCellProps;
  currentTenant: ITenant;
}

const UpdateTenantAction = ({ gridCellProps, currentTenant }: IProps) => {
  const navigate = useNavigate();

  const openTenantDetails = () => {
    const detailedTenantInfoPagePath =
      NAV_PATH_TENANT_DETAIL_MANAGEMENT +
      "?webserverUrl=" +
      encodeURI(currentTenant.webserverUrl);
      
    localStorage.setItem(
      LOCAL_STORAGE_TENANT_DETAILS_KEY,
      JSON.stringify(currentTenant)
    );
    navigate(detailedTenantInfoPagePath, {
      state: {
        webserverUrl: currentTenant.webserverUrl,
        name: currentTenant.name,
        description: currentTenant.description,
        lizenzname: currentTenant.lizenzname,
        accounts: currentTenant.accounts,
      },
    });
  };

  return (
    <>
      <Button className="no-border" onClick={openTenantDetails}>
        <EditIcon className={"table-icon"} />
      </Button>
    </>
  );
};

export default UpdateTenantAction;
