import { useNavigate } from "react-router-dom";
import { EVENT_PERMISSION_DENIED, EVENT_SESSION_EXPIRED } from "../utils/Constants";
import { AbkEventEmitter } from "../utils/AbkEventEmitter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSessionExpired } from "../stateManagement/sessionSlice";

const GlobalEventHandler = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    
  
    useEffect(() => {
        // hanlder for sessionExpired event
        const handleSessionExpired = () => {
          dispatch(setSessionExpired(true));
        };
    
        // Handler for permissionDenied event
        const handlerPermissionDenied = () => {
          console.log("in App.tsxhandlerPermissionDenied ");
          // navigate(NAV_PATH_PERMISSION_DENIED); // Navigate to permission denied page
        };
    
        // Set up event listeners
        // sets up an event listener for the sessionExpired event.
        // When this event is emitted, the handleSessionExpired function will be called.
        AbkEventEmitter.on(EVENT_SESSION_EXPIRED, handleSessionExpired);
        AbkEventEmitter.on(EVENT_PERMISSION_DENIED, handlerPermissionDenied);
    
        // This return function is a cleanup mechanism that removes the event listener when the component unmounts or before the effect re-runs.
        // This helps prevent memory leaks and unintended behavior.
        return () => {
          AbkEventEmitter.off(EVENT_SESSION_EXPIRED, handleSessionExpired);
          AbkEventEmitter.off(EVENT_PERMISSION_DENIED, handlerPermissionDenied);
        };
      }, [
        dispatch,
        // , navigate
      ]);
  
    return null; // This component doesn't render anything
  };
  
  export default GlobalEventHandler;