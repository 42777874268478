import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { GET_CHECK_LOGIN_URL } from "../../../global/utils/Constants";


const useCheckLoginQuery = (redirectUrl: string) => {
    return useQuery({
        queryKey: ["CheckLogin"],
        queryFn: () => axios
            .get<string>(GET_CHECK_LOGIN_URL+redirectUrl)
            .then(res => res.data),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (err: AxiosError) => err,
    })
}
export default useCheckLoginQuery;