import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import DeleteIcon from "../../../../global/icons/DeleteIcon";
import { IAccount } from "../../../../global/interfaces/IAccount";
import DeleteAccountDialog from "./DeleteAccountDialog";

interface IProps {
  handleDeleteDone: (webserverUrl: string) => void;
  account: IAccount;
}

const DeleteAccountAction = (props: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleDeleteDone = () => {
    props.handleDeleteDone(props.account.email);
    toggleDialog();
  };

  const handleCancelDialog = () => {
    toggleDialog();
  };

  return (
    <>
      <span className="k-command-cell">
        <Button className="no-border" onClick={toggleDialog}>
          <DeleteIcon className={"table-icon"} />
        </Button>
        {showDialog && (
          <DeleteAccountDialog
            account={props.account}
            handleDeleteDone={handleDeleteDone}
            handleCancelDialog={handleCancelDialog}
          />
        )}
      </span>
    </>
  );
};

export default DeleteAccountAction;
