import "./TenantManagement.scss";
import AccountsTable from "./components/accountManagement/AllAccountsTable";

const AccountManagement = () =>{

    return (
        <>
      <div className="container-allDataTable">
      <h1>Alle Benutzerkonten</h1>
        <AccountsTable />
      </div>
        </>
        
    )
}
export default AccountManagement;