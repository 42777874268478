import { useEffect } from "react";
import { IAccount } from "../../../../../global/interfaces/IAccount";
import { useEmailValidation } from "../../../hooks/useEmailValidation";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";
import { ITenantAccountAssociation } from "../../../interfaces/ITenantAccountAssociation";
import usePostNewAssociationQuery from "../../../queries/usePostNewAssociationQuery";
import AccountCommonForm from "./AccountCommonForm";

interface AssociateFormProps {
  onSubmitDone: () => void;
  associatedTenantId: string;
  handleCancelDialog: () => void;
}

const AssociateForm = ({
  onSubmitDone,
  associatedTenantId,
  handleCancelDialog,
}: AssociateFormProps) => {
  const initialValues: IAccount = {
    email: "",
    latestUsedTenant: null,
    isAlwaysRedirectToLatestUsedTenant: false,
    roles: [],
    lastLogin: null,
    tenants: [],
  };

  const {
    email,
    handleEmailChange,
    allowSubmit,
    validationError,
    emailValidator,
    resetValidator,
  } = useEmailValidation("", AccountFormMode.Associate);

  const postNewAssociationQuery = usePostNewAssociationQuery();

  const onSubmit = (formValues: { [name: string]: any }) => {
    if (allowSubmit) {
      const newAssociation: ITenantAccountAssociation = {
        email: formValues.email,
        webserverUrl: associatedTenantId,
      };

      postNewAssociationQuery.mutate(newAssociation, {
        onSuccess: () => {
          onSubmitDone();
        },
      });
    }
  };

  useEffect(() => {
    resetValidator();
  }, []);

  return (
    <AccountCommonForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleCancelDialog={handleCancelDialog}
      allowSubmit={allowSubmit}
      emailProps={{
        onChange: handleEmailChange,
        validator: emailValidator,
        validationError: validationError,
      }}
      mode={AccountFormMode.Associate}
      allTenants={[]} // No tenants needed for associate mode
    />
  );
};

export default AssociateForm;
