import { useMutation } from "react-query"
import axios, { AxiosError } from "axios"
import { POST_ADD_TENANT } from "../../../global/utils/Constants"
import { ITenantBasic } from "../../../global/interfaces/ITenantBasic"
import { ITenant } from "../../../global/interfaces/ITenant"


const usePostNewTenantQuery = () => {
    return useMutation<ITenant, AxiosError, ITenantBasic>(async (newTenant: ITenantBasic) =>
        await axios.post(POST_ADD_TENANT, 
            newTenant, 
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.data),
        {
        }
    );

}

export default usePostNewTenantQuery