import axios, { AxiosError } from "axios"
import { useMutation } from "react-query"
import { IAccount } from "../../../global/interfaces/IAccount"
import { POST_CREATE_ACCOUNT } from "../../../global/utils/Constants"


const usePostNewAccountQuery = () => {
    return useMutation<IAccount, AxiosError, IAccount>(async (newAccount: IAccount) =>
        await axios.post(POST_CREATE_ACCOUNT, 
            newAccount, 
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.data),
        {
        }
    );

}

export default usePostNewAccountQuery;