import axios from "axios";
import { useQuery } from "react-query";
import { GET_ACCOUNT_USER_INFO_URL } from "../../../global/utils/Constants";
import { IAccountUserInfo } from "../interfaces/IAccountUserInfo";


const useGetAccountUserInfoQuery = () => {
    return useQuery({
        queryKey: ["AccountUserInfo"],
        queryFn: () => axios
            .get<IAccountUserInfo>(GET_ACCOUNT_USER_INFO_URL)
            .then(res => {
                return res.data
            }
            ),
        enabled: false,
        retry: false,
    })
}
export default useGetAccountUserInfoQuery;