import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { GET_CHECK_SESSION_URL } from "../../../global/utils/Constants";


const useCheckSessionQuery = () => {
    return useQuery({
        queryKey: ["CheckSession"],
        queryFn: () => axios
            .get<boolean>(GET_CHECK_SESSION_URL)
            .then(res => res.data),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (err: AxiosError) => err,
    })
}
export default useCheckSessionQuery;