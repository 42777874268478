import { Dialog } from "@progress/kendo-react-dialogs";
import { IAccount } from "../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../interfaces/AccountFormMode";
import AccountCreateForm from "./form/AccountCreateForm";
import AccountUpdateForm from "./form/AccountUpdateForm";
import AssociateForm from "./form/AssociateForm";

interface IProps {
  account?: IAccount;
  dialogTitle: string;
  mode: AccountFormMode;
  onSubmit: () => void;
  associatedTenantId?: string;
  handleCancelDialog: () => void;
  allTenants?: ITenant[];
}

const OpenedAccountDialog = ({
  account,
  onSubmit,
  dialogTitle,
  mode,
  associatedTenantId = "",
  allTenants = [],
  handleCancelDialog,
}: IProps) => {
  const emptyAccount: IAccount = {
    email: "",
    lastLogin: null,
    latestUsedTenant: null,
    isAlwaysRedirectToLatestUsedTenant: false,
    roles: [],
    tenants: [],
  };

  const renderForm = () => {
    switch (mode) {
      case AccountFormMode.Associate:
        return (
          <AssociateForm
            onSubmitDone={onSubmit}
            associatedTenantId={associatedTenantId}
            handleCancelDialog={handleCancelDialog}
          />
        );
      case AccountFormMode.Create:
        return (
          <AccountCreateForm
            onSubmitDone={onSubmit}
            handleCancelDialog={handleCancelDialog}
            allTenants={allTenants}
          />
        );
      case AccountFormMode.Update:
        return (
          <AccountUpdateForm
            onSubmitDone={onSubmit}
            handleCancelDialog={handleCancelDialog}
            allTenants={allTenants}
            account={account ? account : emptyAccount}
          />
        );
    }
  };

  return (
    <>
      <Dialog width={400} title={dialogTitle} onClose={handleCancelDialog}>
        {renderForm()}
      </Dialog>
    </>
  );
};

export default OpenedAccountDialog;
