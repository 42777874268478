import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import SuccessNotificationToast from "../../../../global/components/SuccessNotificationToast";
import { IAccount } from "../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../interfaces/AccountFormMode";
import OpenedAccountDialog from "./OpenedAccountDialog";
import EditIcon from "../../../../global/icons/EditIcon";

interface IProps {
  account?: IAccount;
  mode: AccountFormMode;
  onSubmitDone: () => void;
  associatedTenantId?: string;
  //   handleCancelDialog: () => void;
  allTenants?: ITenant[];
}

const AccountDialogController = ({
  account,
  mode,
  onSubmitDone,
  associatedTenantId,
  //   handleCancelDialog: handleCancelDialog,
  allTenants,
}: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleOnSubmitDone = () => {
    toggleDialog();
    setShowSuccessToast(true);
    onSubmitDone();
  };

  const handleCancelDialog = () => {
    toggleDialog();
  };

  const getDialogToggleButtonText = () => {
    switch (mode) {
      case AccountFormMode.Associate:
        return "Benutzerkonto Hinzufügen";
      case AccountFormMode.Create:
        return "Benutzerkonto Erstellen";
      case AccountFormMode.Update:
        return <EditIcon className={"table-icon"} />;
    }
  };

  const getDialogTitle = () => {
    switch (mode) {
      case AccountFormMode.Associate:
        return "Zugehörigkeit erstellen";
      case AccountFormMode.Create:
        return "Neues Benutzerkonto erstellen";
      case AccountFormMode.Update:
        return "Benutzerkonto Bearbeiten";
    }
  };

  const getSuccessMessage = () => {
    switch (mode) {
      case AccountFormMode.Associate:
        return "Zugehörigkeit erstellt.";
      case AccountFormMode.Create:
        return "Neues Benutzerkonto erstellt.";
      case AccountFormMode.Update:
        return "Benutzerkonto bearbeitet.";
    }
  };

  return (
    <>
      <Button title={getDialogTitle()} onClick={toggleDialog}>
        {getDialogToggleButtonText()}
      </Button>

      {showDialog && (
        <OpenedAccountDialog
          handleCancelDialog={handleCancelDialog}
          dialogTitle={getDialogTitle()}
          mode={mode}
          onSubmit={handleOnSubmitDone}
          associatedTenantId={associatedTenantId}
          allTenants={allTenants}
          account={account}
        />
      )}

      <SuccessNotificationToast
        message={getSuccessMessage}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
      />
    </>
  );
};

export default AccountDialogController;
