import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { DropdownType } from "../types/DropdownType";

interface MultiSelectFieldProps extends FieldRenderProps {
  // data: ITenant[] ;
  data:DropdownType;
  textField: string;
  dataItemKey: string;
  placeholder: string;
}

const MultiSelectFieldInForm: React.FC<MultiSelectFieldProps> = (
  fieldRenderProps
) => {
  const {
    value,
    id,
    valid,
    disabled,
    hint,
    optional,
    onChange,
    onBlur,
    label,
    visited,
    data,
    textField,
    dataItemKey,
    placeholder,
  } = fieldRenderProps;



  return (
    <div>
      <label htmlFor={id} className={"k-form-label"}>
        {label}
      </label>
      <MultiSelect
        id={id}
        data={data}
        textField={textField}
        dataItemKey={dataItemKey}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {hint && !visited && <span className={"k-form-hint"}>{hint}</span>}
      {valid === false && visited && <Error>{fieldRenderProps.error}</Error>}
    </div>
  );
};

export default MultiSelectFieldInForm;
