import { RouterProvider } from "react-router-dom";
import "./App.scss";
import UnauthorizedDialog from "./components/UnauthorizedDialog";

import GlobalEventHandler from "./global/components/GlobalEventHandler";
import { router } from "./global/router/Router";
import ErrorNotificationToast from "./global/components/ErrorNotificationToast";

function App() {
  return (
    <div className="content">
      <UnauthorizedDialog />
      <GlobalEventHandler />
      <ErrorNotificationToast />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
