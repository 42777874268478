import useFetchTenants from "../../hooks/useFetchTenants";
import { AccountFormMode } from "../../interfaces/AccountFormMode";
import AccountDialogController from "./AccountDialogController";

interface IProps {
  handleAddAccountDone: () => void;
}

const AddAccount = ({ handleAddAccountDone }: IProps) => {
  const { allTenants } = useFetchTenants();

  return (
    <>
      <AccountDialogController
        mode={AccountFormMode.Create}
        onSubmitDone={handleAddAccountDone}
        allTenants={allTenants}
      />
    </>
  );
};

export default AddAccount;
