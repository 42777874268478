import { Operation } from "fast-json-patch";
import { ITenantBasic } from "../../../global/interfaces/ITenantBasic";
import { SubmitTenantData } from "../../../global/types/SubmitTenantData";
import TenantForm from "../components/TenantForm";
import "../TenantManagement.scss";
import usePatchUpdateTenantQuery from "../queries/usePatchUpdateTenantQuery";
import { IUpdateTenantArguments } from "../interfaces/IUpdateTenantArguments";
import { ITenant } from "../../../global/interfaces/ITenant";
import SuccessNotificationToast from "../../../global/components/SuccessNotificationToast";
import { useState } from "react";

interface IProp {
  tenantBasic: ITenantBasic;
}

const BasicTenantDetails = ({ tenantBasic }: IProp) => {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  // deep copy
  const tenant: ITenantBasic = JSON.parse(JSON.stringify(tenantBasic));

  const patchUpdateTenantQuery = usePatchUpdateTenantQuery();

  const handleUpdateTenant = async (data: SubmitTenantData) => {
    console.log("in BasicTenantDetails-> handleUpdateTenant about to send update request to BE")
    const patch = data as Operation[];

    const queryArguments: IUpdateTenantArguments = {
      webserverUrl: tenant.webserverUrl,
      patch: patch,
    };

    patchUpdateTenantQuery.mutate(queryArguments, {
      onSuccess: (updatedTenant: ITenant) => {
        setShowSuccessToast(true);
      },
    });
  };

  return (
    <>
      <div className="container-tenantForm">
        <TenantForm
          isNewTenant={false}
          tenantBasic={tenant}
          handleCancel={() => {}}
          handleSubmit={handleUpdateTenant}
        />
      </div>
      <SuccessNotificationToast
        message={"Unternehmen erfolgreich aktualisiert."}
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
      />
    </>
  );
};

export default BasicTenantDetails;
