import { useMutation } from "react-query";
import { IApiError } from "../../../global/interfaces/IApiError";
import axios from "axios";
import { DELETE_ACCOUNT } from "../../../global/utils/Constants";

const useDeleteAccountQuery = () => {
    return useMutation<void, IApiError, string>(async (accountId: string) =>
        await axios.delete(DELETE_ACCOUNT + encodeURI(accountId)), {}
    )
}

export default useDeleteAccountQuery;