import { GridCellProps } from "@progress/kendo-react-grid";
import DeleteAssociationAction from "./DeleteAssociationAction";


interface IProps {
  handleDeleteAccountAssociationDone: (accountId: string) => void;
  gridCellProps: GridCellProps;
  tenantId: string;
}


export const TenantAccountAssociationActionsCell = (props:IProps) => {
  const handleDeleteAssociationDone = (email: string) => {
    props.handleDeleteAccountAssociationDone(email);
  };

  return (
    <>
      <td>
        <DeleteAssociationAction
          gridCellProps={props.gridCellProps}
          handleDeleteAssociationDone={handleDeleteAssociationDone}
          accountBasic={props.gridCellProps.dataItem} 
          tenantId={props.tenantId}
          />
          
      </td>

    </>
  );
};

export default TenantAccountAssociationActionsCell;
