import { IAccount } from "../../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";
import AccountCommonForm from "./AccountCommonForm";
import { useEmailValidation } from "../../../hooks/useEmailValidation";

interface CreateUpdateFormProps {
  onSubmit: (values: { [key: string]: any }) => void;
  account: IAccount;
  mode: AccountFormMode.Create | AccountFormMode.Update;
  handleCancelDialog: () => void;
  allTenants: ITenant[];
}

const AccountCreateUpdateForm = ({
  onSubmit,
  account,
  mode,
  handleCancelDialog,
  allTenants,
}: CreateUpdateFormProps) => {

  const initialValues: IAccount = JSON.parse(JSON.stringify(account));

  const {
    email,
    handleEmailChange,
    allowSubmit,
    validationError,
    emailValidator,
  } = useEmailValidation(account.email, mode);

  const getEmailProps = () => {
    return {
      onChange: handleEmailChange,
      validator: emailValidator,
      validationError: validationError,
      initialValue: mode === AccountFormMode.Update ? account.email : undefined,
    };
  };

  return (
    <AccountCommonForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleCancelDialog={handleCancelDialog}
      allowSubmit={allowSubmit}
      emailProps={getEmailProps()}
      mode={mode}
      allTenants={allTenants}
    />
  );
};

export default AccountCreateUpdateForm;
