import { useQuery } from "react-query";
import axios from "axios";
import { GET_VALIDATE_WEBSERVERURL_OF_TENANT } from "../../../global/utils/Constants";
import { IValidationError } from "../../../global/interfaces/IValidationError";


const useGetValidateNewWebserverUrl = (webserverUrl: string) => {
    return useQuery({
        queryKey: ["ValidateNewWebserverUrl", webserverUrl],
        queryFn: () => axios
            .get<IValidationError>(GET_VALIDATE_WEBSERVERURL_OF_TENANT + encodeURI(webserverUrl))
            .then(res => res.data),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
    })
}
export default useGetValidateNewWebserverUrl;