import { useMutation } from "react-query";
import { IApiError } from "../../../global/interfaces/IApiError";
import axios from "axios";
import { DELETE_TENANT } from "../../../global/utils/Constants";

const useDeleteTenantQuery = () => {
    return useMutation<void, IApiError, string>(async (webserverUrl: string) =>
        await axios.delete(DELETE_TENANT + encodeURI(webserverUrl)), {}
    )
}

export default useDeleteTenantQuery;