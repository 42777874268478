import { IAccount } from "../../../../global/interfaces/IAccount";
import useFetchTenants from "../../hooks/useFetchTenants";
import { AccountFormMode } from "../../interfaces/AccountFormMode";
import AccountDialogController from "./AccountDialogController";

interface IProps {
  currentAccount: IAccount;
  handleUpdateDone: () => void;
}

const UpdateAccountAction = ({ currentAccount, handleUpdateDone }: IProps) => {
  const { allTenants } = useFetchTenants();

  return (
    <>
      <AccountDialogController
        mode={AccountFormMode.Update}
        account={currentAccount}
        onSubmitDone={handleUpdateDone}
        allTenants={allTenants}
      />
    </>
  );
};

export default UpdateAccountAction;
