import ApiKeyDescription from "./ApiKeyDescription";
import ApiKeyTable from "./ApiKeyTable";
import '../../Settings.scss'

const ApiKeyContainer = () => {

  return (
    <>
      <div className="apiKeyContainerOuter">
        <div className="apiKeyContainerInner">
          <h1>API Key-Verwaltung</h1>
          <ApiKeyDescription />
          <ApiKeyTable />
        </div>
      </div>
    </>
  );
};

export default ApiKeyContainer;
