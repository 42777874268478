import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { IAccount } from "../../../global/interfaces/IAccount";
import { PUT_UPDATE_ACCOUNT } from "../../../global/utils/Constants";
import { IPutUpdateAccountArguments } from "../interfaces/IPutUpdateAccountArguments";


const usePutUpdateAccountQuery = () => {
    return useMutation<IAccount, AxiosError, IPutUpdateAccountArguments>(
        async ({ email, updateAccountDTO }: IPutUpdateAccountArguments) =>
            await axios.put(
                PUT_UPDATE_ACCOUNT + encodeURI(email),
                updateAccountDTO,
                { headers: { 'Content-Type': 'application/json' } }
            ).then(res => res.data),
        {
        }
    );
}

export default usePutUpdateAccountQuery;