import { useEffect, useState } from "react";
import axios from "axios";
import { ITenant } from "../../../global/interfaces/ITenant";
import useGetAllTenantsQuery from "../queries/useGetAllTenantsQuery";

const useFetchTenants = () => {
    const [allTenants, setAllTenants] = useState<ITenant[]>([]);
  
    const {
      refetch: refetchAllTenants,
      data: allFetchedTenants,
      isFetched: areAllTenantsFetched,
      isError: isErrorGetAllTenants,
      error: errorGetAllTenants,
    } = useGetAllTenantsQuery();
  
    useEffect(() => {
      if (areAllTenantsFetched) {
        setAllTenants(allFetchedTenants ?? []);
      }
    }, [areAllTenantsFetched, allFetchedTenants]);
  
    useEffect(() => {
      if (isErrorGetAllTenants) {
        console.log(
          "detected error in fetching all tenants with status: " +
            (axios.isAxiosError(errorGetAllTenants)
              ? errorGetAllTenants.status
              : "NO STATUS as it is not an axios error")
        );
        console.log(errorGetAllTenants);
      }
    }, [isErrorGetAllTenants, errorGetAllTenants]);
  
    return { allTenants, refetchAllTenants, isFetched: areAllTenantsFetched, isError: isErrorGetAllTenants, error: errorGetAllTenants };
  };
  
  export default useFetchTenants;