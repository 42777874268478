import { Operation, compare } from "fast-json-patch";
import Roles from "../../../../../global/enums/Roles";
import { IAccount } from "../../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";
import { IPutUpdateAccountArguments } from "../../../interfaces/IPutUpdateAccountArguments";
import { IUpdateAccountArguments } from "../../../interfaces/IUpdateAccountArguments";
import { IUpdateAccountDTO } from "../../../interfaces/IUpdateAccountDTO";
import usePatchUpdateAccountQuery from "../../../queries/usePatchUpdateAccountQuery";
import usePutUpdateAccountQuery from "../../../queries/usePutUpdateAccountQuery";
import AccountCreateUpdateForm from "./AccountCreateUpdateForm";

interface AccountUpdateFormProps {
  onSubmitDone: (updatedAccount: IAccount) => void;
  handleCancelDialog: () => void;
  allTenants: ITenant[];
  account: IAccount;
}

const AccountUpdateForm = ({
  onSubmitDone,
  handleCancelDialog,
  allTenants,
  account,
}: AccountUpdateFormProps) => {
  const initialAccountValues: IAccount = JSON.parse(JSON.stringify(account));

  const patchUpdateAccountQuery = usePatchUpdateAccountQuery();

  const putUpdateAccountQuery = usePutUpdateAccountQuery();

  const onSubmit = (formValues: { [name: string]: any }) => {
    const modifiedAccount: IAccount = {
      email: account.email,
      latestUsedTenant: account.latestUsedTenant,
      isAlwaysRedirectToLatestUsedTenant:
        account.isAlwaysRedirectToLatestUsedTenant,
      roles: formValues.roles,
      lastLogin: account.lastLogin,
      tenants: formValues.tenants.map((tenant) => tenant.webserverUrl),
    };


    const patchableAccountValues = {
      ...initialAccountValues,
      tenants: initialAccountValues.tenants.map((tenant) => tenant.webserverUrl),
    }

    const patch: Operation[] = compare(patchableAccountValues, modifiedAccount);
    console.log("patch: ");
    console.log(patch);

    const queryArguments: IUpdateAccountArguments = {
      email: account.email,
      patch: patch,
    };

    console.log("queryArguments of patch update: ")
    console.log(queryArguments)

    console.log("about to send patch for updating account")
    patchUpdateAccountQuery.mutate(queryArguments, {
      onSuccess: (updatedAccount: IAccount) => {
        onSubmitDone(updatedAccount);
        console.log("done with the patch account update")
      },
    });



    // const modifiedRoles = modifiedAccount.roles;
    // const filteredRoles: Roles[] = modifiedRoles?.filter((role) => {
    //   return role !== null
    // }) ?? [];

    // const putUpdateAccountDTO: IUpdateAccountDTO = {
    //   roles: filteredRoles.length > 0? filteredRoles : null,
    //   tenants: modifiedAccount.tenants,
    // };
    // const queryArgumentsPutUpdate: IPutUpdateAccountArguments = {
    //   email: account.email,
    //   updateAccountDTO: putUpdateAccountDTO,
    // };

    // putUpdateAccountQuery.mutate(queryArgumentsPutUpdate, {
    //   onSuccess: (updatedAccount: IAccount) => {
    //     onSubmitDone(updatedAccount);
    //   },
    // });
  };

  

  return (
    <AccountCreateUpdateForm
      account={initialAccountValues}
      allTenants={allTenants}
      mode={AccountFormMode.Update}
      handleCancelDialog={handleCancelDialog}
      onSubmit={onSubmit}
    />
  );
};

export default AccountUpdateForm;
