import InfoIcon from "../../../../global/icons/InfoIcon";

const ApiKeySicherheitshinweis = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <InfoIcon style={{ marginRight: "5px" }} />
          <span style={{ fontWeight: "bold" }}> Sicherheitshinweis:</span>
        </div>
        <p> *txt*
        Bitte behandeln Sie Ihren API-Key mit Sorgfalt und geben Sie ihn nicht an unbefugte Personen weiter. 
        Ihr API-Key ist Ihre persönliche Identifikation und sollte wie ein Passwort behandelt werden. 
        Im Falle eines Verlusts oder Diebstahls Ihres API-Keys können Sie ihn jederzeit hier auf dieser Seite löschen und einen neuen erstellen.
        </p>
      </div>
    </>
  );
};

export default ApiKeySicherheitshinweis;
