export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "/api";


export const GET_ASSOCIATED_TENANTS_URL: string = BACKEND_URL + "/account/tenants"
export const PUT_ALWAYS_REDIRECT_URL: string = BACKEND_URL + "/account/alwaysRedirect"
export const GET_ACCOUNT_EMAIL_URL: string = BACKEND_URL + "/account/email"
export const GET_ACCOUNT_INFO_URL: string = BACKEND_URL + "/account/"
export const GET_ACCOUNT_USER_INFO_URL: string = BACKEND_URL + "/account/userinfo"
export const GET_All_ACCOUNTS: string = BACKEND_URL + "/account/all"
export const GET_VALIDATE_NEW_EMAIL: string = BACKEND_URL + "/account/validate?email="
export const POST_CREATE_ACCOUNT: string = BACKEND_URL + "/account/add"
export const DELETE_ACCOUNT: string = BACKEND_URL + "/account/delete?accountId="
export const PATCH_UPDATE_ACCOUNT: string = BACKEND_URL + "/account/update?email="
export const PUT_UPDATE_ACCOUNT: string = BACKEND_URL + "/account/update?email="
export const GET_RANDOM_BE_ERROR_URL: string = BACKEND_URL + "/account/error"

export const CREATE_SESSION_URL: string = BACKEND_URL + "/login/createSession?webserverUrl="
export const AUTHENTICATION_URL: string = BACKEND_URL + "/login/"
export const GET_CHECK_LOGIN_URL: string = BACKEND_URL + "/login/validateLogin?redirectUrl="
export const GET_CHECK_SESSION_URL: string = BACKEND_URL + "/login/validateSession"
export const GET_LOGOUT_URL: string = BACKEND_URL + "/logout"
export const GET_LOGOUT_URL_ALL: string = BACKEND_URL + "/logout?scope=universe"

export const GET_ALL_API_KEYS: string = BACKEND_URL + "/apikey/all"
export const POST_CREATE_API_KEY: string = BACKEND_URL + "/apikey/add"
export const DELETE_DELETE_API_KEY: string = BACKEND_URL + "/apikey/delete"

export const GET_ALL_TENANTS: string = BACKEND_URL + "/tenant/all"
export const DELETE_TENANT: string = BACKEND_URL + "/tenant/delete?webserverUrl="
export const PATCH_UPDATE_TENANT: string = BACKEND_URL + "/tenant/update?webserverUrl="
export const POST_ADD_TENANT: string = BACKEND_URL + "/tenant/add"
export const GET_VALIDATE_WEBSERVERURL_OF_TENANT: string = BACKEND_URL + "/tenant/validate?webserverUrl="
export const GET_VALIDATE_LIZENZNAME_OF_TENANT: string = BACKEND_URL + "/tenant/lizenzname/validate?lizenzname="
export const TENANT_ASSOCIATION: string = BACKEND_URL + "/tenant/association"


export const NAV_PATH_ACCOUNTS_MANAGEMENT = "/verwaltung-accounts";
export const NAV_PATH_TENANTS_MANAGEMENT = "/verwaltung-tenants";
export const NAV_PATH_TENANT_DETAIL_MANAGEMENT = "/verwaltung-tenant/details";
export const NAV_PATH_PERMISSION_DENIED = "/no-permission";
export const NAV_PATH_TENANT_SELECTION = "/tenant-selection";


export const LOCAL_STORAGE_TENANT_DETAILS_KEY = "tenantDetails";

export const EVENT_SESSION_EXPIRED = "sessionExpired";
export const EVENT_PERMISSION_DENIED = "permissionDenied";
export const EVENT_BE_ERROR_OCCURED = "beErrorOccured";
