import axios from "axios";
import { useQuery } from "react-query";
import { IAccountBasic } from "../../../global/interfaces/IAccountBasic";
import { TENANT_ASSOCIATION } from "../../../global/utils/Constants";
// import { convertRoles } from "../../../global/utils/RoleConverter";


const useGetAllAssociatedAccountsQuery = (webserverUrl: string) => {
    return useQuery({
        queryKey: ["getAllAssociatedAccounts"],
        queryFn: () => axios.get<IAccountBasic[]>(
            TENANT_ASSOCIATION
            + "?webserverUrl="
            + encodeURI(webserverUrl)
        )
            .then(res => { 
                return res.data 
            }),
        retry: 1
    })
}

export default useGetAllAssociatedAccountsQuery;