import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import sessionReducer from './sessionSlice'
import userReducer from './userSlice';
import tenantDetailReducer from './tenantDetailSlice';
import associatedTenantsReducer from './associatedTenantsSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';


// Redux Persist configuration
const persistConfig = {
  key: 'root', // Key to be used to store the state in the storage
  storage, // The storage engine to use, here we are using localStorage
  // whiltelist: ['auth', 'user'], // Choose which reducers you want to persist
  blacklist: ['session', 'tenantDetail', 'associatedTenants', 'auth',  'user'],
}

// combine all reducers
const reducers = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  user: userReducer,
  tenantDetail: tenantDetailReducer,
  associatedTenants: associatedTenantsReducer
})

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore Redux Persist actions
      },
    }),
});

// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     session: sessionReducer,
//     user: userReducer,
//     tenantDetail: tenantDetailReducer,
//   },
// });

// Infer the type of `store`
// export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export the store and persistor
export const persistor = persistStore(store);
export default store;