import React from "react";
import { IAccountUserInfo } from "../../interfaces/IAccountUserInfo";
import WelcomeName from "./WelcomeName";

interface IPropsWelcomeMessage {
  accountUserInfo: IAccountUserInfo;
}

const WeclomeMessage = ({ accountUserInfo }: IPropsWelcomeMessage) => {
  return (
    <div>
      <p className="welcome-text center-text">
        Willkommen bei ABK9
        <WelcomeName accountUserInfo={accountUserInfo} />
      </p>
    </div>
  );
};

export default WeclomeMessage;
