import TenantsTable from "./components/TenantsTable";
import "./TenantManagement.scss";

const TenantManagement = (props) => {
  return (
    <>
      <div className="container-allDataTable">
        <h1>Alle Unternehmen</h1>
        <TenantsTable />
      </div>
      
    </>
  );
};

export default TenantManagement;
