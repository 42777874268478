import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import App from "./App";
import store, { persistor } from "./global/stateManagement/store";
import { AbkEventEmitter } from "./global/utils/AbkEventEmitter";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import { PersistGate } from "redux-persist/es/integration/react";
import {
  EVENT_BE_ERROR_OCCURED,
  EVENT_SESSION_EXPIRED
} from "./global/utils/Constants";

const mutationCache = new MutationCache({
  onError: (error, _variables, _context, mutation) => {
    // If this mutation has an onError defined, skip this
    if (mutation.options.onError) return;

    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        AbkEventEmitter.emit(EVENT_SESSION_EXPIRED);
        // } else if (error.response?.status === 403) {
        //   // navigate(EVENT_PERMISSION_DENIED)
        //   AbkEventEmitter.emit(EVENT_PERMISSION_DENIED);
      } else {
        AbkEventEmitter.emit(EVENT_BE_ERROR_OCCURED, formatError(error));
      }
    }
  },
});

const queryCache = new QueryCache({
  onError: (error, query) => {
    // If this query has an onError defined, skip this
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        AbkEventEmitter.emit(EVENT_SESSION_EXPIRED);
        // } else if (error.response?.status === 403) {
        //   AbkEventEmitter.emit(EVENT_PERMISSION_DENIED);
      } else {
        AbkEventEmitter.emit(EVENT_BE_ERROR_OCCURED, formatError(error));
      }
    }
  },
});

const formatError = (error) => {
  return (
    <pre>
      {error.message}
      {".\n "}
      {error.response?.data.detail}
      {"\n instance: "}
      {error.response?.data.instance}
      {"\n time: "}
      {error.response?.data.time}
    </pre>
  );
  // return error.message +
  //   ". \n " +
  //   error.response?.data.detail +
  //   " \n instance: " +
  //   error.response?.data.instance +
  //   " \n time: " +
  //   error.response?.data.time;
};

const queryClient = new QueryClient({
  mutationCache,
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
