import React from "react";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { IAssociatedTenant } from "../../interfaces/IAssociatedTenant";
import { IAssociatedTenants } from "../../interfaces/IAssociatedTenants";
import { IAccountUserInfo } from "../../interfaces/IAccountUserInfo";
import { CREATE_SESSION_URL } from "../../../../global/utils/Constants";
import { Error } from "@progress/kendo-react-labels";
import useUpdateAlwaysRedirectQuery from "../../../settings/queries/useUpdateAlwaysRedirectQuery";
import WeclomeMessage from "./WelcomeMessage";
import TenantDropdown from "./TenantDropdown";
import AlwaysRedirectCheckbox from "./AlwaysRedirectCheckbox";
import RedirectButton from "./RedirectButton";
import { useNavigate } from "react-router-dom";

interface IPropsTenantsSelection {
  tenants: IAssociatedTenants;
  accountUserInfo: IAccountUserInfo;
}

const TenantSelection = ({
  tenants,
  accountUserInfo,
}: IPropsTenantsSelection) => {
  const [selectedTenant, setSelectedTenant] = useState<
    IAssociatedTenant | undefined
  >();
  const [isAlwaysRedirectChecked, setIsAlwaysRedirectChecked] = useState(false);
  const updateAlwaysRedirectQuery = useUpdateAlwaysRedirectQuery();

  const [tenantsLoaded, setTenantsLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // if multiple tenants exist, set the default tenant:
    if (tenants?.tenants !== undefined && tenants?.tenants !== null) {
      setTenantsLoaded(true);
    }
    if (tenants?.tenants?.length) {
      // set default tenant
      setDefaultTenant(tenants);
    }
  }, [tenants]);

  const setDefaultTenant = (tenants: IAssociatedTenants) => {
    // if multiple tenants exist, set the default tenant:
    if (
      tenants.tenants !== undefined &&
      tenants.tenants !== null &&
      tenants.tenants.length > 1
    ) {
      // Set default value based on latestUsedTenant
      // if latestUsedTenant could not be found or is null
      //  -> use first tenant in list as default tenant
      setSelectedTenant(tenants.latestUsedTenant || tenants.tenants[0]);
    }
  };

  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setSelectedTenant(event.target.value);
  };

  const handleCheckBoxChange = (event) => {
    setIsAlwaysRedirectChecked(event.value);
  };

  const handleWeiterButton = () => {
    // update isAlwaysRedirectToLatestUsedTenant flag
    updateAlwaysRedirectQuery.mutate(isAlwaysRedirectChecked);

    // redirect to selected tenant
    window.location.href =
      CREATE_SESSION_URL + encodeURIComponent(selectedTenant!.webserverUrl);
  };

  return (
    <>
      {tenants?.tenants && (
        <>
          {tenants?.tenants?.length <= 0 ? (
            <Error>
              Arbeiten ohne eingetragenen Tenant für diesen User nicht möglich,
              l.ilg@abk.at fragen. *txt*
            </Error>
          ) : (
            <>
              <WeclomeMessage accountUserInfo={accountUserInfo} />
              <TenantDropdown
                tenants={tenants}
                selectedTenant={selectedTenant}
                onSelect={handleDropdownChange}
              />
              <AlwaysRedirectCheckbox
                isChecked={isAlwaysRedirectChecked}
                onChange={handleCheckBoxChange}
              />
              <RedirectButton
                selectedTenant={selectedTenant}
                onClick={handleWeiterButton}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default TenantSelection;
