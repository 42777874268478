import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { ICreateApiKey } from "../../interfaces/ICreateApiKey";
import TitleInput from "./TitleInput";
import { Input } from "@progress/kendo-react-inputs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";

const initialValues: ICreateApiKey = {
  title: "Automation",
  description: "",
};

interface IProps {
  handleSubmit: (values: { [name: string]: any }) => Promise<void>;
  handleCloseDialog: () => void;
}

const AddApiKeyForm = ({ handleSubmit, handleCloseDialog }: IProps) => {
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setAllowSubmit(initialValues.title !== "");
  }, []);

  const requiredValidator = (value: string, currentValue: string) => {
    return value || currentValue === initialValues.title
      ? ""
      : "Sie müssen einen Verwendungszweck angeben";
  };

  const hanldeDialogClose = () => {
    setError(null);
    handleCloseDialog();
  };

  const onSubmit = (formValues: { [name: string]: any }) => {
    // need to do that to use intialValues
    const mergedValues = { ...initialValues, ...formValues };
    handleSubmit(mergedValues);
  };

  const handleTitleChange = (event) => {
    const { value } = event.target;
    setAllowSubmit(value !== "");
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      ignoreModified={true}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <FieldWrapper>
            <Field
              lang="de"
              name="title"
              component={TitleInput}
              labelClassName={"k-form-label"}
              label="Verwendungszweck"
              validator={(value: string) =>
                requiredValidator(value, formRenderProps.valueGetter("title"))
              }
              onChange={handleTitleChange}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Field
              name="description"
              component={Input}
              labelClassName={"k-form-label"}
              label="Anmerkung"
            />
          </FieldWrapper>

          <DialogActionsBar>
            <Button onClick={hanldeDialogClose}>Abbrechen</Button>
            <Button type="submit" disabled={!allowSubmit}>
              Erstellen
            </Button>
          </DialogActionsBar>
          {error && <p>{error}</p>}
        </FormElement>
      )}
    ></Form>
  );
};

export default AddApiKeyForm;
