import React from "react";
import { IAssociatedTenants } from "../../interfaces/IAssociatedTenants";
import { IAssociatedTenant } from "../../interfaces/IAssociatedTenant";
import {
  DropDownList
} from "@progress/kendo-react-dropdowns";

interface IPropsTenantDropdown {
  tenants: IAssociatedTenants;
  selectedTenant?: IAssociatedTenant;
  onSelect: (event: any) => void;
}

const TenantDropdown = ({
  tenants,
  selectedTenant,
  onSelect,
}: IPropsTenantDropdown) => {
  const itemRender = (li, itemProps) => {
    const formatedText = `${itemProps.dataItem.name} (${itemProps.dataItem.webserverUrl})`;

    const itemChildren = <span>{formatedText}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }

    const formatedText = `${value.name} (${value.webserverUrl})`;
    const children = [<span key={1}>{formatedText}</span>];
    return React.cloneElement(
      element,
      {
        ...element.props,
      },
      children
    );
  };

  return (
    <div className="tenant-selection-row">
      <p className="portal-normal-text portal-gray-text-color">
      Unternehmen Auswahl: *txt*
      </p>
      <DropDownList
        id="tenant-selection-dropdown"
        style={{ width: "250px" }}
        data={tenants?.tenants}
        itemRender={itemRender}
        textField="name"
        value={selectedTenant ? selectedTenant : null}
        valueRender={valueRender}
        onChange={onSelect}
      />
    </div>
  );
};

export default TenantDropdown;
