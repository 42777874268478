import { Button } from "@progress/kendo-react-buttons";
import DeleteIcon from "../../../../global/icons/DeleteIcon";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useState } from "react";
import DeleteApiKeyDialog from "./DeleteApiKeyDialog";

interface IProps {
  handleDeleteApiKeyDone: (webserverUrl: string) => void;
  gridCellProps: GridCellProps;
}

const DeleteApiKeyColumn = (props: IProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dataItem = props.gridCellProps.dataItem;

  const toggleDialog = () => {
    setShowDialog((prevShowDialog) => !prevShowDialog);
  };

  const handleCloseDialog = () => {
    props.handleDeleteApiKeyDone(dataItem.id);
    toggleDialog();
  };

  return (
    <td>
      <Button className="no-border" onClick={toggleDialog}>
        <DeleteIcon />
      </Button>
      {showDialog && (
        <DeleteApiKeyDialog
          dataItem={dataItem}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </td>
  );
};

export default DeleteApiKeyColumn;
