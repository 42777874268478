import axios, { AxiosError } from "axios";
import { useQuery } from "react-query"
import { GET_ALL_API_KEYS } from "../../../global/utils/Constants";
import { IApiKeyWithoutKeyValue } from "../interfaces/IApiKeyWithoutKeyValue";
import { useDispatch } from "../../../global/stateManagement/hooks";
import { setSessionExpired } from "../../../global/stateManagement/sessionSlice"

const useGetAllApiKeysQuery = () => {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: ["getAllApiKeys"],
        queryFn: () => axios.get<IApiKeyWithoutKeyValue[]>(GET_ALL_API_KEYS)
            .then(res => {
                return res.data
            }
            ),
            onError: (err: AxiosError) => {
                console.log("I reached useGetAllApiKeysQuery onError with status: " + err.status)
                if (err.status === 401){
                    console.log("I should never be reached.")
                    dispatch(setSessionExpired(true))
                }
                return err}
            ,
        retry: 1
    })
}

export default useGetAllApiKeysQuery;