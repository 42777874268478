import { GridCellProps } from "@progress/kendo-react-grid";
import { IAccount } from "../../../../global/interfaces/IAccount";
import DeleteAccountAction from "./DeleteAccountAction";
import UpdateAccountAction from "./UpdateAccountAction";

interface IProps {
  handleDeleteDone: (email: string) => void;
  gridCellProps: GridCellProps;
  handleUpdateDone: () => void;
}

const AccountActionsCell = (props: IProps) => {
  const handleDeleteDone = (email: string) => {
    props.handleDeleteDone(email);
  };

  return (
    <>
      <td>
        <DeleteAccountAction
          handleDeleteDone={handleDeleteDone}
          account={props.gridCellProps.dataItem as IAccount}
        />
        <UpdateAccountAction
          currentAccount={props.gridCellProps.dataItem}
          handleUpdateDone={props.handleUpdateDone}
        />
      </td>
    </>
  );
};

export default AccountActionsCell;
