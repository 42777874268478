import { Button } from "@progress/kendo-react-buttons";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { IAccount } from "../../../../../global/interfaces/IAccount";
import { ITenant } from "../../../../../global/interfaces/ITenant";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";
import {
  EmailField,
  RolesDropdownMultiselectField,
  TenantsField,
} from "./AccountFormCommonFields";

interface CommonFormProps {
  initialValues: IAccount;
  onSubmit: (values: { [key: string]: any }) => void;
  handleCancelDialog: () => void;
  allowSubmit: boolean;
  emailProps: {
    onChange: (e: any) => void;
    validator: (value: string) => string | undefined;
    validationError: string | null;
    initialValue?: string;
  };
  mode: AccountFormMode;
  allTenants: ITenant[];
}

const AccountCommonForm = ({
  initialValues,
  onSubmit,
  handleCancelDialog,
  allowSubmit,
  emailProps,
  mode,
  allTenants,
}: CommonFormProps) => {
  const getButtonText = () => {
    switch (mode) {
      case AccountFormMode.Create:
        return "Erstellen";
      case AccountFormMode.Update:
        return "Speichern";
      case AccountFormMode.Associate:
        return "Zuordnen";
      default:
        return "Submit";
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <EmailField
            initialValue={
              mode === AccountFormMode.Update
                ? emailProps.initialValue
                : undefined
            }
            validator={emailProps.validator}
            onChange={emailProps.onChange}
            validationError={emailProps.validationError}
            readOnly={mode === AccountFormMode.Update}
          />
          {mode !== AccountFormMode.Associate && (
            <TenantsField data={allTenants} />
          )}
          {mode !== AccountFormMode.Associate && (
            <RolesDropdownMultiselectField />
          )}
          <DialogActionsBar>
            <Button type="button" onClick={handleCancelDialog}>
              Abbrechen
            </Button>
            <Button type="submit" disabled={!allowSubmit}>
              {getButtonText()}
            </Button>
          </DialogActionsBar>
        </FormElement>
      )}
    />
  );
};

export default AccountCommonForm;
