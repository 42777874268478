import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useGetValidateLizenzname from "../queries/useGetValidateLizenzname";
import { IValidationError } from "../../../global/interfaces/IValidationError";

const DEBOUNCE_TIME_MS = 500;

export const useLizenznameValidation = (initialLizenzname: string) => {
    const [lizenzname, setLizenzname] = useState(initialLizenzname);
    const [allowSubmit, setAllowSubmit] = useState<boolean>(true);
    const [validationError, setValidationError] = useState<string | null>(null);

    const {
        refetch,
    } = useGetValidateLizenzname(lizenzname);

    const debouncedRefetch = useCallback(
        debounce(async (value) => {
            if (value) {
                const { data } = await refetch({ queryKey: ["ValidateLizenzname", value] })
                handleValidationResult(data)

            }
        }, DEBOUNCE_TIME_MS),
        []
    );

    const handleLizenznameChange = (e) => {
        const value = e.target.value;
        setLizenzname(value);
        // setValidationError(null);
        debouncedRefetch(value);
    };

    const handleValidationResult = (validationBEError: IValidationError | undefined) => {

        if (validationBEError && validationBEError.validationErrors && validationBEError.validationErrors.length > 0) {
            setAllowSubmit(false);

            let errorMsgs: string[] = []

            // iterate thru each error and create approrpiate validation message
            for (var abk9Error of validationBEError.validationErrors) {
                if (abk9Error.status === 400) {
                    const msg = "Lizenzname ist ungültig.";
                    errorMsgs.push(msg)
                }
            }

            // if there are any validation errors, then set them
            if (errorMsgs.length > 0) {
                setValidationError(errorMsgs.join('\r\n'))
            }
        } else {
            setAllowSubmit(true);
            setValidationError(null)
        }

    }


    const resetValidator = () => {
        setLizenzname("");
        setValidationError(null)
        setAllowSubmit(true);
    }


    useEffect(() => {
        return () => {
            debouncedRefetch.cancel();
        };
    }, [debouncedRefetch]);

    const lizenznameValidator = (value) => {
        if (!value) {
            return "";
        }

        if (validationError) {
            return validationError;
        }

        return "";
    };

    return {
        lizenzname,
        handleLizenznameChange,
        allowSubmit,
        validationError,
        lizenznameValidator,
        resetValidator,
    };
};