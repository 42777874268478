import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import Roles from "../../../../../global/enums/Roles";

interface RolesDropdownMultiselectProps extends FieldRenderProps {
  data: Roles[];
  placeholder: string;
}

const RolesDropdownMultiselect: React.FC<RolesDropdownMultiselectProps> = (
  fieldRenderProps
) => {
  const {
    value,
    id,
    valid,
    disabled,
    hint,
    optional,
    onChange,
    onBlur,
    label,
    visited,
    data,
    textField,
    dataItemKey,
    placeholder,
  } = fieldRenderProps;

  const handleOnChange = (event) => {
    onChange(event);
  };

  return (
    <div>
      <label htmlFor={id} className={"k-form-label"}>
        {label}
      </label>
      <MultiSelect
        id={id}
        data={data}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {hint && !visited && <span className={"k-form-hint"}>{hint}</span>}
      {valid === false && visited && <Error>{fieldRenderProps.error}</Error>}
    </div>
  );
};

export default RolesDropdownMultiselect;
