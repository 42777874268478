import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useGetValidateNewWebserverUrl from "../queries/useGetValidateNewWebserverUrlQuery";
import { IValidationError } from "../../../global/interfaces/IValidationError";

const DEBOUNCE_TIME_MS = 500;

export const useWebserverUrlValidation = (initialUrl: string) => {
  const [webserverUrl, setWebserverUrl] = useState(initialUrl);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<string | null>(null);

  const {
    refetch,
  } = useGetValidateNewWebserverUrl(webserverUrl);

  const debouncedRefetch = useCallback(
    debounce(async (value) => {
      if (value) {
        const { data } = await refetch({ queryKey: ["ValidateNewWebserverUrl", value] });
        handleValidationResult(data)
      }
    }, DEBOUNCE_TIME_MS),
    [refetch]
  );

  const handleWebserverUrlChange = (e) => {
    const value = e.target.value;
    setWebserverUrl(value);
    debouncedRefetch(value);
  };

  const resetValidator = () => {
    setWebserverUrl("");
    setValidationError(null)

  }

  const handleValidationResult = (validationBEError: IValidationError | undefined) => {

    if(validationBEError && validationBEError.validationErrors && validationBEError.validationErrors.length > 0){
      setAllowSubmit(false);

      let errorMsgs: string[] = []

      // iterate thru each error and create approrpiate validation message
      for (var abk9Error of validationBEError.validationErrors) {
        if (abk9Error.status === 400) {
          const msg = "Url ist ungültig.";
          errorMsgs.push(msg)
        } else if (abk9Error.status === 409){
          const msg = "WebserverUrl existiert schon - geben Sie eine andere ein.";
          errorMsgs.push(msg);
        }

      }

       // if there are any validation errors, then set them
       if (errorMsgs.length > 0) {
        setValidationError(errorMsgs.join('\r\n'))
      }

    } else {
      setAllowSubmit(true);
      setValidationError(null)
    }
  

  }

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel();
    };
  }, [debouncedRefetch]);

  const webserverUrlValidator = (value) => {
    if (!value) {
      return "*txt* Webserver Url darf nicht leer sein.";
    }

    if (validationError) {
      return validationError;
    }

    return "";
  };

  return {
    webserverUrl,
    handleWebserverUrlChange,
    allowSubmit,
    validationError,
    webserverUrlValidator,
    resetValidator,
  };
};