import {
  GridColumn as Column,
  Grid,
  GridRowClickEvent,
  GridToolbar,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITenant } from "../../../global/interfaces/ITenant";
import {
  LOCAL_STORAGE_TENANT_DETAILS_KEY,
  NAV_PATH_TENANT_DETAIL_MANAGEMENT,
} from "../../../global/utils/Constants";
import useGetAllTenantsQuery from "../queries/useGetAllTenantsQuery";
import TenantActionsCell from "./TenantActionsCell";
import AddTenant from "./addTenant/AddTenant";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

const DATA_ITEM_KEY: string = "webserverUrl";

const TenantsTable = () => {
  const navigate = useNavigate();
  const [allTenants, setAllTenants] = useState<ITenant[]>([]);

  const initialSort: Array<SortDescriptor> = [
    { field: DATA_ITEM_KEY, dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);
  

  const {
    refetch: refetchAllTenants,
    data: allFetchedTenants,
    isFetched: areAllTenantsFetched,
    isError: isErrorGetAllTenants,
    error: errorGetAllTenants,
  } = useGetAllTenantsQuery();

  useEffect(() => {
    if (areAllTenantsFetched) {
      if (allFetchedTenants) {
        setAllTenants(allFetchedTenants);
      } else {
        setAllTenants([]);
      }
    }
  }, [areAllTenantsFetched, allFetchedTenants]);

  useEffect(() => {
    if (isErrorGetAllTenants) {
      console.log(
        "detected error in fetching all tenants with status: " +
          (axios.isAxiosError(errorGetAllTenants)
            ? errorGetAllTenants.status
            : "NO STATUS as it is not an axios error")
      );
      console.log(errorGetAllTenants);
    }
  }, [isErrorGetAllTenants, errorGetAllTenants]);

  const removeTenantFromList = (webserverUrl: string) => {
    const updatedTenantList = allTenants.filter(
      (tenant) => tenant.webserverUrl !== webserverUrl
    );
    setAllTenants(updatedTenantList);
    refetchAllTenants();
  };

  const showTenantDetails = (e: GridRowClickEvent) => {
    const currentTenant = e.dataItem as ITenant;

    const detailedTenantInfoPagePath =
      NAV_PATH_TENANT_DETAIL_MANAGEMENT +
      "?webserverUrl=" +
      encodeURI(currentTenant.webserverUrl);

    localStorage.setItem(
      LOCAL_STORAGE_TENANT_DETAILS_KEY,
      JSON.stringify(currentTenant)
    );
    navigate(detailedTenantInfoPagePath, {
      state: currentTenant,
    });
  };

  return (
    <>
      {allTenants ? (
        <Grid
          data={orderBy(allTenants, sort)}
          style={{
            maxHeight: "400px",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onRowClick={showTenantDetails}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <AddTenant handleAddTenantDone={refetchAllTenants} />
          </GridToolbar>
          <Column field="webserverUrl" title="Webserver Url" editable={false} />
          <Column field="name" title="Name" editable={true} editor="text" />
          <Column
            field="description"
            title="Description"
            editable={true}
            editor="text"
          />
          <Column
            field="lizenzname"
            title="Lizenzname"
            editable={true}
            editor="text"
          />
          <Column
            filterable={false}
            field="actions"
            title="Aktionen"
            cell={(props) => (
              <TenantActionsCell
                gridCellProps={props}
                handleDeleteDone={removeTenantFromList}
              />
            )}
          />
        </Grid>
      ) : (
        <p>keine Daten gefunden.</p>
      )}
    </>
  );
};

export default TenantsTable;
