import { DropDownButton } from "@progress/kendo-react-buttons";
import moreIcon from "../../../global/icons/moreIcon.png";
import logoutIcon from "../../../global/icons/logout.png";
import settingsIcon from "../../../global/icons/settings.png";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { GET_LOGOUT_URL_ALL, NAV_PATH_ACCOUNTS_MANAGEMENT, NAV_PATH_TENANTS_MANAGEMENT } from "../../../global/utils/Constants";
import { useSelector } from "react-redux";
import { selectIsUserPortalAdmin } from "../../../global/stateManagement/userSlice";

interface IProps {
  onLogout: () => void;
}

interface IDropdownItem{
  text:string,
  icon: string,
  navigatePath: string,
  toolTipText: string
}

const TenantSelectionMoreButton = (props: IProps) => {
  const navigate = useNavigate();

  const isPortalAdmin =  useSelector(selectIsUserPortalAdmin);

  

  const createItems = ()=>{
    const items: IDropdownItem[] = [
      {
        text: "Einstellungen",
        icon: settingsIcon,
        navigatePath: "/einstellungen",
        toolTipText: ""
      },
      { text: "Log Out", icon: logoutIcon, navigatePath: "/", toolTipText: "" },
    ];

    if(isPortalAdmin){
      const tenantManagementItem: IDropdownItem = {
        text: "Verwaltung Unternehmen ",
        icon: settingsIcon,
        navigatePath: NAV_PATH_TENANTS_MANAGEMENT,
        toolTipText: ""
      }
      items.push(tenantManagementItem)

      const accountManagementItem: IDropdownItem = {
        text: "Verwaltung Benutzerkonten",
        icon: settingsIcon,
        navigatePath: NAV_PATH_ACCOUNTS_MANAGEMENT,
        toolTipText: ""
      }
      items.push(accountManagementItem)
    }

    return items;
  }


  const itemRender = (props) => {
    return (
        <div>
          <img src={props.item.icon} alt="icon" />
          <span style={{paddingLeft:10}}>{props.item.text}</span>
        </div>
    );
  };

  const handleItemClick =async (event: any) => {
    if(event.item.text === "Log Out"){
      window.location.href = GET_LOGOUT_URL_ALL;
    }else{
      navigate(event.item.navigatePath);
    }
  };

  return (
    <div className="more-btn">
      <Tooltip  anchorElement="target" parentTitle={true}>
      <DropDownButton
        title="Mehr Optionen anzeigen"
        items={createItems()}
        itemRender={itemRender}
        fillMode={"flat"}
        imageUrl={moreIcon}
        onItemClick={handleItemClick}
      />
      </Tooltip>
    </div>
  );
};

export default TenantSelectionMoreButton;
