import axios from "axios";
import { useQuery } from "react-query";
import { GET_RANDOM_BE_ERROR_URL } from "../utils/Constants";


const useGetRandomBeErrorQuery = () => {
    return useQuery({
        queryKey: ["AccountUserInfo"],
        queryFn: () => axios
            .get<void>(GET_RANDOM_BE_ERROR_URL)
            .then(res => {
                return res.data
            }
            ),
        enabled: false,
        retry: false,
    })
}
export default useGetRandomBeErrorQuery;