import axios from "axios";
import { useQuery } from "react-query"
import { ITenant } from "../../../global/interfaces/ITenant";
import { GET_ALL_TENANTS } from "../../../global/utils/Constants";


const useGetAllTenantsQuery = ()=>{
    return useQuery({
        queryKey: ["getAllTenants"],
        queryFn:()=> axios.get<ITenant[]>(GET_ALL_TENANTS)
        .then(res => {return res.data}),
        retry: 1
    })
}

export default useGetAllTenantsQuery;