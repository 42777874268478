import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";

interface IProps {
  apiKeyValue: string;
  handleCloseDialog: () => void;
}
const NewApiKeyInfo = ({ apiKeyValue, handleCloseDialog }: IProps) => {
  const [copied, setCopied] = useState(false);

  const hanldeCopyApiKeyValue = () => {
    navigator.clipboard.writeText(apiKeyValue!);
    setCopied(true);
  };

  const hanldeDialogClose = () => {
    setCopied(false);
    handleCloseDialog();
  };

  return (
    <>
      <p>
        Bitte speichern Sie diesen geheimen Schlüssel an einem sicheren und
        zugänglichen Ort. Aus Sicherheitsgründen können Sie ihn nicht erneut
        anschauen. Wenn Sie diesen geheimen Schlüssel verlieren, müssen Sie
        einen neuen generieren.
      </p>
      <div className="centerContainerOuter">
        <Input value={apiKeyValue} readOnly style={{ marginRight: "8px" }} />
        <Button onClick={hanldeCopyApiKeyValue}>
          {copied ? "Kopiert" : "Kopieren"}
        </Button>{" "}
      </div>

      <DialogActionsBar>
        <Button onClick={hanldeDialogClose}>Schließen</Button>
      </DialogActionsBar>
    </>
  );
};

export default NewApiKeyInfo;
