import AccountDialogController from "../../../components/accountManagement/AccountDialogController";
import { AccountFormMode } from "../../../interfaces/AccountFormMode";

interface IProps {
  tenantId: string;
  handleAddAccountToTenantDone: () => void;
}
const AddAccountToTenant = ({
  tenantId,
  handleAddAccountToTenantDone,
}: IProps) => {
  return (
    <>
      <AccountDialogController
        mode={AccountFormMode.Associate}
        associatedTenantId={tenantId}
        onSubmitDone={handleAddAccountToTenantDone}
      />
    </>
  );
};

export default AddAccountToTenant;
