import { useMutation } from "react-query";
import axios from "axios";
import { PUT_ALWAYS_REDIRECT_URL } from "../../../global/utils/Constants";
import { IApiError } from "../../../global/interfaces/IApiError";

const useUpdateAlwaysRedirectQuery = () => {

    return useMutation({
        mutationKey: ['updateAlwaysRedirectTenant', 'updated'],
        mutationFn: async (alwaysRedirect: boolean) => await axios.put(PUT_ALWAYS_REDIRECT_URL, 
            alwaysRedirect, 
            { headers: { 'Content-Type': 'application/json' } }),
        onError: (err: IApiError) => {
            // Handle error
            console.log("in useUpdateAlwaysRedirectQuery running into an error: " + JSON.stringify(err));
        }
    })
}

export default useUpdateAlwaysRedirectQuery;