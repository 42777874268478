import React from "react";
import "../../../styles/globalStyles.scss";
import MicrosoftLoginButton from "./MicrosoftLoginButton";



const WelcomeComponent = () => {

  return (
    <>
      <div>
        <p className="portal-normal-text center-text portal-gray-text-color">
          Loggen Sie sich ein, um fortzufahren. *txt*
        </p>
      </div>
      <div className="portal-div-to-center">
       <MicrosoftLoginButton currentPath={window.location.pathname} />
        
      </div>     
    </>
  );
};

export default WelcomeComponent;
