import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

const DateCell = (props: GridCellProps) => {
  const { dataItem, field } = props;

  if (!field || !dataItem[field]) {
    return <td></td>;
  }

  const date = new Date(dataItem[field]);

  if (isNaN(date.getTime())) {
    return <td>Invalid date</td>;
  }

  // Define your date and time format
  const formattedDate = new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format(date);

  return <td>{formattedDate}</td>;
};

export default DateCell;